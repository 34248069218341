import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import base64 from 'base-64'
import {requestData} from 'services'
import {WINDOW_MODE_MOBILE_WIDTH} from 'consts'
import {useTranslation} from 'i18n'
import {getDefaultAvatar, useHistory} from 'utils'
import {WindowModeType} from 'types'
import {useWindowMode} from 'windows'
import {Avatar, Button, ButtonOutline, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {useSelector} from 'lib/redux'
import {AuthLoginApple, AuthLoginGoogle} from 'pages/auth'

interface StyledButtonsContainerProps {
  mode: WindowModeType
}

const StyledOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0;
  }
`

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: ${convertUnit(600)};
  @media (max-width: ${WINDOW_MODE_MOBILE_WIDTH}px) {
    width: 100%;
    padding: ${convertUnit(25)};
  }
`

const StyledDescription = styled.div`
  margin-top: ${convertUnit(10)};
  margin-bottom: ${convertUnit(15)};
`

const StyledButtonsContainer = styled.div<StyledButtonsContainerProps>`
  margin: ${convertUnit(20)} 0;
  ${({mode}) => ({
    flexDirection: mode === 'mobile' ? 'column' : 'row',
    gap: mode === 'mobile' ? 12 : 8,
    marginBottom: mode === 'mobile' ? 12 : 20,
  })}
  display: flex;
  justify-content: space-between;
  width: fill-available;
`

const StyledButtonOutline = styled(ButtonOutline)`
  width: fill-available;
  white-space: nowrap;
  overflow: hidden;
`
const StyledButton = styled(Button)`
  width: fill-available;
`
const StyledLastSavedContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertUnit(12)};
  justify-content: center;
  align-items: center;
  width: fill-available;
  background-color: ${({theme}) => theme.white_3};
  border-radius: ${convertUnit(8)};
  padding: ${convertUnit(12)} ${convertUnit(8)};
`
const StyledFooter = styled.div`
  margin-top: ${convertUnit(20)};
`

interface TemplateAuthAccessFullPageProps {
  onClose?(): void
}

export default function TemplateAuthAccessFullPage({
  onClose,
}: TemplateAuthAccessFullPageProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const history = useHistory()
  const {username, photo, refreshToken} = useSelector('lastUserState') || {}
  const [loading, setLoading] = useState(false)
  const hasSavedLoginInfo = useMemo(
    () => !!(username && photo && refreshToken),
    [photo, refreshToken, username],
  )

  const handlePressLogin = useCallback(() => {
    const user = 'web'
    const password = process.env.AUTH_CLIENT_PASSWORD
    if (refreshToken) {
      setLoading(true)
      requestData('auth_refresh_token', {
        data: {
          code_verifier: process.env.AUTH_CODE_VERIFIER,
          grant_type: 'refresh_token',
          refresh_token: refreshToken,
        },
        headers: {
          Authorization: `Basic ${base64.encode(`${user}:${password}`)}`,
        },
        onRequestReceived: () => {
          setLoading(false)
          onClose && onClose()
        },
      })
    }
  }, [onClose, refreshToken])

  const handleRenderLoginButton = useMemo(
    () =>
      hasSavedLoginInfo ? (
        <StyledButtonOutline
          color="gray_5"
          label={translate('global:login')}
          onClick={() => {
            const {pathname, search} = history.location
            if (history.location.pathname === '/') {
              history.push('auth_login', {})
            } else {
              history.pushQuery({
                path: 'auth_login',
                state: undefined,
                queryObject: {
                  next: encodeURIComponent(`${pathname}${search}`),
                },
              })
            }
          }}
        />
      ) : (
        <StyledButton
          label={translate('global:login')}
          onClick={() => {
            const {pathname, search} = history.location
            if (history.location.pathname === '/') {
              history.push('auth_login', {})
            } else {
              history.pushQuery({
                path: 'auth_login',
                state: undefined,
                queryObject: {
                  next: encodeURIComponent(`${pathname}${search}`),
                },
              })
            }
          }}
        />
      ),
    [hasSavedLoginInfo, history, translate],
  )

  const handleRenderSavedLogin = useMemo(
    () =>
      hasSavedLoginInfo && (
        <StyledLastSavedContainer>
          <Avatar
            src={photo ?? getDefaultAvatar()}
            alt={photo ?? getDefaultAvatar()}
            size={90}
          />
          <StyledButton
            color="white_1"
            label={translate('auth:loginAs', {username})}
            onClick={handlePressLogin}
            disabled={loading}
          />
          {handleRenderLoginButton}
        </StyledLastSavedContainer>
      ),
    [
      handlePressLogin,
      handleRenderLoginButton,
      hasSavedLoginInfo,
      loading,
      photo,
      translate,
      username,
    ],
  )

  return (
    <StyledOuterContainer>
      <StyledContainer>
        <Paragraph fontSize="xl" fontWeight="bold">
          {translate('forkygram:authAccessHeader')}
        </Paragraph>
        <StyledDescription>
          <Paragraph color="gray_5">
            {translate('forkygram:authAccessDescription')}
          </Paragraph>
        </StyledDescription>
        {handleRenderSavedLogin}
        {!hasSavedLoginInfo && handleRenderLoginButton}
        <StyledButtonsContainer mode={mode}>
          <AuthLoginGoogle
            label={translate('auth:loginButtonGoogleAuthAccess')}
          />
          <AuthLoginApple
            label={translate('auth:loginButtonAppleAuthAccess')}
          />
        </StyledButtonsContainer>
        <StyledButton
          label={translate('global:signUp')}
          color="primary_5"
          backgroundColor="white_3"
          onClick={() => history.push('auth_easy_signup', {})}
        />
        <StyledFooter>
          <Paragraph color="gray_5" fontWeight="medium">
            {translate('auth:identifierOneAccount')}
          </Paragraph>
        </StyledFooter>
      </StyledContainer>
    </StyledOuterContainer>
  )
}
