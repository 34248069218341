import React, {useCallback, useEffect, useState} from 'react'
import {LazyLoadImage} from 'react-lazy-load-image-component'
import styled from 'styled-components'
import {IMAGE_ASSET} from 'consts'
import {ImageProps} from './ImageProps'

interface StyledLazyLoadProps {
  isLazyLoad: boolean
}

const StyledLazyLoad = styled(LazyLoadImage)`
  display: block;
  font-size: 0;
  object-fit: cover;
`

const StyledImg = styled.img`
  display: block;
  font-size: 0;
  object-fit: cover;
`

const StyledImgContainer = styled.div<StyledLazyLoadProps>`
  position: relative;
  overflow: hidden;
  border-radius: inherit;
`

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export default function Image({
  useLazyLoad = false,
  src: srcBase,
  fallbackSrc,
  placeholder,
  height,
  width,
  onError,
  ...props
}: ImageProps) {
  const placeholderImage = IMAGE_ASSET('giftshop', 'placeholder.png')
  const [src, setSrc] = useState<string | undefined>(srcBase)

  const handleError = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement>) => {
      onError && onError(e)
      if (fallbackSrc) {
        setSrc(fallbackSrc)
      }
    },
    [onError, fallbackSrc],
  )
  const disableRightClick = useCallback(() => {
    window.addEventListener('DOMContentLoaded', () => {
      document.addEventListener('contextmenu', (event: MouseEvent) => {
        const target = event.target as HTMLElement
        if (target.tagName.toLowerCase() === 'img') {
          event.preventDefault()
        }
      })
    })
  }, [])

  useEffect(() => {
    setSrc(srcBase)
  }, [srcBase])

  return (
    <StyledImgContainer isLazyLoad={useLazyLoad} style={{height, width}}>
      <StyledOverlay />
      {useLazyLoad ? (
        <StyledLazyLoad
          onContextMenu={disableRightClick}
          {...props}
          src={src}
          placeholderSrc={placeholder || placeholderImage}
          onError={handleError}
        />
      ) : (
        <StyledImg
          onContextMenu={disableRightClick}
          {...props}
          src={src}
          placeholder={placeholder}
          onError={handleError}
        />
      )}
    </StyledImgContainer>
  )
}
