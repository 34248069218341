import {TranslationKeyGlobal} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_GLOBAL_EN: TranslationResource<TranslationKeyGlobal> = {
  translation: {
    'global:accept': 'Accept',
    'global:accepted': 'Accepted',
    'global:activate': 'Activate',
    'global:activated': 'Activated',
    'global:add': 'Add',
    'global:addNew': 'Add New',
    'global:addNow': 'Add Now',
    'global:addCart': 'Add to Cart',
    'global:additionalDataModalButton': 'Go to Edit Profile',
    'global:additionalDataModalDescription':
      'Before you continue, please register your email address in the edit profile.',
    'global:additionalDataModalTitle': 'Additional Data Required',
    'global:addWishlist': 'Add to FotoYu',
    'global:all': 'All',
    'global:allContents': 'All Contents',
    'global:allDone': 'All Done!',
    'global:amount': 'Amount',
    'global:and': 'and',
    'global:apply': 'Apply',
    'global:back': 'Back',
    'global:backgroundMessage': 'The Best Content',
    'global:backgroundMessage_second': 'Just For You',
    'global:backTo': 'Back to {{page}}',
    'global:bad': 'Bad',
    'global:basicPrice': 'Basic Price',
    'global:beta': 'Beta',
    'global:buy': 'Buy',
    'global:by': 'By',
    'global:cancel': 'Cancel',
    'global:cancelConfirmation':
      'Are you sure you want to cancel? The report will not be saved',
    'global:cart': 'Cart',
    'global:cashDeposit': 'Cash Deposit',
    'global:change': 'Change',
    'global:changesSaved': 'Changes have been saved',
    'global:charCount': '{{count}}/{{max}}',
    'global:chat': 'Chat',
    'global:chatCreator': 'Chat Creator',
    'global:chatTheCreator': 'Chat the Creator',
    'global:checkoutPayment': 'Payment',
    'global:city': 'City',
    'global:clear': 'Clear',
    'global:close': 'Close',
    'global:closeContent': 'Close Content',
    'global:communities': 'Communities',
    'global:complete': 'Complete',
    'global:confirm': 'Confirm',
    'global:confirmation': 'Confirmation',
    'global:confirmed': 'Confirmed',
    'global:congratulations': 'Congratulations!',
    'global:connectionInterrupted': 'Connection interrupted',
    'global:content': 'Content',
    'global:contentNotFound': 'Content Not Found',
    'global:continue': 'Continue',
    'global:copy': 'Copy',
    'global:copyLink': 'Copy Link',
    'global:createAccount': 'Create Account',
    'global:createnickname': 'Create Nickname',
    'global:createnicknameDesc':
      'Create a nickname to protect your privacy. You can change this later.',
    'global:createnicknameFail': "There's a problem when sending your data",
    'global:createnicknamePlaceholder': 'Input your new nickname',
    'global:createnicknameSuccess': 'Your nickname has been created.',
    'global:creators': 'Creators',
    'global:currency': 'Currency',
    'global:current': 'Current',
    'global:date': 'Date',
    'global:dateFormat': '{{hour}}h {{minute}}m',
    'global:dateOfBirth': 'Date of Birth',
    'global:day': 'Day',
    'global:days': '{{day}} days',
    'global:day_friday': 'Friday',
    'global:day_monday': 'Monday',
    'global:day_saturday': 'Saturday',
    'global:day_sunday': 'Minggu',
    'global:day_thursday': 'Thursday',
    'global:day_tuesday': 'Tuesday',
    'global:day_wednesday': 'Wednesday',
    'global:delete': 'Delete',
    'global:deleteContent': 'Delete Content',
    'global:deleted': 'Deleted',
    'global:description': 'Description',
    'global:download': 'Download',
    'global:duplicate': 'Duplicate',
    'global:iBanking': 'i-Banking',
    'global:edit': 'Edit',
    'global:editContent': 'Edit Content',
    'global:email': 'Email',
    'global:emailNew': 'New Email',
    'global:events': 'Events',
    'global:expandContent': 'Expand Content',
    'global:expandMenu': 'Expand Menu',
    'global:faceRecognition': 'Face Recognition',
    'global:fail': 'Failed',
    'global:failed': 'Failed',
    'global:failedLoad': 'Failed to Load',
    'global:faq': 'FAQ',
    'global:fileName': 'File Name View',
    'global:filter': 'Filter',
    'global:forUser': 'For {{nickname}}',
    'global:fotoyu': 'FotoYu',
    'global:fotoyuAdmin': 'Fotoyu Admin',
    'global:fototree': 'FotoTree',
    'global:fotoyuSupportTeam': 'FotoYu support team:',
    'global:free': 'Free',
    'global:from': 'From',
    'global:fullname': 'Fullname',
    'global:generateThumbnail':
      'Generating thumbnail... please come back later.',
    'global:good': 'Good',
    'global:hashtag': 'Hashtag',
    'global:here': 'here',
    'global:hide': 'Hide',
    'global:high': 'High',
    'global:hires': 'Hi-Res',
    'global:history': 'History',
    'global:hour': 'Hour',
    'global:host': 'Host',
    'global:howItWorks': 'How It Works',
    'global:iAgree': 'I Agree',
    'global:invoiceCopied': 'Invoice successfully copied',
    'global:idr': 'IDR',
    'global:kycModalInfo': 'The next step will turn on your camera.',
    'global:kycModalSubtitle':
      'Activate RoboYu to search for your photos with face recognition technology.',
    'global:language': 'Language',
    'global:later': 'Later',
    'global:learnMore': 'Learn more.',
    'global:less': 'less',
    'global:limitedAccess': 'Limited Access',
    'global:limitedAccessDesc': 'Does not meet minimum age requirement.',
    'global:link': 'link',
    'global:linkCopied': 'Link successfully copied',
    'global:live': 'Live',
    'global:location': 'Location',
    'global:login': 'Login',
    'global:loading': 'Loading...',
    'global:logout': 'Log Out',
    'global:logoutDescription': 'Logout from this device',
    'global:mBanking': 'm-Banking',
    'global:main': 'Main',
    'global:maintenanceEnd': 'until <date>{{date}}</date>',
    'global:maintenanceStart': 'on <date>{{date}}</date>',
    'global:masonryView': 'Masonry View',
    'global:medium': 'Medium',
    'global:messageError': 'Oops... something unexpected happened',
    'global:message': 'Message',
    'global:messageError_code-400': 'Format data is not valid',
    'global:messageError_code-401':
      'The access token that is currently used is no longer valid',
    'global:messageError_code-403':
      'You are prohibited from accessing this feature',
    'global:messageError_code-404': 'Page not found',
    'global:messageError_code-408': '$t(global:messageError_timeout)',
    'global:messageError_code-409': 'Resource Conflict: ID has been used',
    'global:messageError_no-connection': 'No Internet Connection',
    'global:messageError_timeout': 'Connection Timeout',
    'global:messageError-execute':
      'Error occured while executing action. Please try again.',
    'global:messageError-fetch':
      'Error occured while fetching data. Please try again.',
    'global:minute': 'Minute',
    'global:mobileNumber': 'Mobile Number',
    'global:mobileNumberNew': 'New Mobile Number',
    'global:month': 'Month',
    'global:month_april': 'April',
    'global:month_august': 'August',
    'global:month_december': 'December',
    'global:month_february': 'February',
    'global:month_january': 'January',
    'global:month_july': 'July',
    'global:month_june': 'June',
    'global:month_march': 'March',
    'global:month_may': 'May',
    'global:month_november': 'November',
    'global:month_october': 'October',
    'global:month_september': 'September',
    'global:more': 'more',
    'global:name': 'Name',
    'global:needHelp': 'Need Help?',
    'global:new': 'new',
    'global:next': 'Next',
    'global:nickname': 'Nickname',
    'global:no': 'No',
    'global:noData': 'No data',
    'global:notes': 'Notes',
    'global:notification': 'Notification',
    'global:notInterest': 'Not Interest',
    'global:notInterested': 'Not Interested',
    'global:notMe': 'Not Me',
    'global:notRegistered': 'Not Registered',
    'global:numberCopied': 'Number successfully copied',
    'global:off': 'Off',
    'global:ok': 'OK',
    'global:okay': 'Okay',
    'global:old': 'old',
    'global:on': 'On',
    'global:options': 'Options',
    'global:password': 'Password',
    'global:passwordConfirm': 'Confirm Password',
    'global:phoneNumber': 'Phone Number',
    'global:photo': 'Photo',
    'global:places': 'Places',
    'global:play': 'Play',
    'global:pleaseTryAgain': 'Please try again in {{countdown}}.',
    'global:pleaseWait': 'Please wait...',
    'global:price': 'Price',
    'global:processed': 'Processed',
    'global:profile': 'Profile',
    'global:publish': 'Publish',
    'global:purchaseDate': 'Purchase date: {{purchased_at}}',
    'global:refresh': 'Refresh',
    'global:refreshPage': 'Refresh',
    'global:register': 'Register',
    'global:reject': 'Reject',
    'global:rejected': 'Rejected',
    'global:remove': 'Remove',
    'global:removeAll': 'Remove All',
    'global:removeCart': 'Remove from Cart',
    'global:removeWishlist': 'Remove from Wishlist',
    'global:report': 'Report Content',
    'global:reportCancel': 'The report will not be saved',
    'global:reportContent': 'Report',
    'global:reportFail': 'Report Failed',
    'global:reportFailMessage':
      "There's a problem when sending your report. Please try again.",
    'global:reportReason': 'Select a reason:',
    'global:reportSuccess': 'Report Successful!',
    'global:reportSuccessMessage':
      'Thankyou for your participation. We will process your report.',
    'global:retry': 'Retry',
    'global:revision': 'Revision',
    'global:rp': 'IDR',
    'global:save': 'Save',
    'global:saveChanges': 'Save Changes',
    'global:search': 'Search',
    'global:seeMore': 'See More',
    'global:select': 'Select',
    'global:selectAll': 'Select All',
    'global:selectedCount': '{{count}} Selected',
    'global:selectThing': 'Select {{thing}}',
    'global:selectVideo': 'Select video to upload',
    'global:send': 'Send',
    'global:settings': 'Settings',
    'global:share': 'Share',
    'global:shareContent': 'Share Content',
    'global:shareContentFailed':
      'Unable to share {{type}} at this time. Please try again later.',
    'global:show': 'Show',
    'global:signUp': 'Sign Up',
    'global:similarAccount': 'Similar Account',
    'global:skip': 'Skip',
    'global:sorry': 'Sorry!',
    'global:sort': 'Sort',
    'global:standard': 'Standard',
    'global:start': 'Start',
    'global:stop': 'Stop',
    'global:stay': 'Stay Here',
    'global:submit': 'Submit',
    'global:success': 'Successful!',
    'global:sure': "Yes, I'm sure",
    'global:tags': 'Tags',
    'global:tapToRetry': 'Tap to Retry',
    'global:time': 'Time',
    'global:title': 'Title',
    'global:to': 'To',
    'global:today': 'Today',
    'global:tomorrow': 'Tomorrow',
    'global:transferATM': 'Transfer ATM',
    'global:transferMiniATM': 'Transfer Mini ATM',
    'global:trees': 'Trees',
    'global:tryAgain': 'Try Again',
    'global:tryRefresh': 'Try to refresh the page.',
    'global:type': 'Type',
    'global:upgrade': 'Upgrade',
    'global:upgradeStatus': 'Upgrade Status',
    'global:upload': 'Upload',
    'global:uploadedImage': 'Uploaded Image',
    'global:uploadVideo': 'Upload Video',
    'global:uploadVideoOptional': 'Upload Video (Optional)',
    'global:username': 'Username',
    'global:validationMaxLength':
      'This form requires maximum number of {{count}} character',
    'global:validationMaxLength_plural':
      'This form requires maximum number of {{count}} characters',
    'global:validationMinLength':
      'This form requires at least {{count}} character',
    'global:validationMinLength_plural':
      'This form requires at least {{count}} characters',
    'global:validationMaxPrice': 'Content maximum price is {{price}}',
    'global:validationMinPrice': 'Content minimum price is {{price}}',
    'global:validationRequired': 'This form is required',
    'global:verified': 'Verified',
    'global:verify': 'Verify',
    'global:video': 'Video',
    'global:view': 'View',
    'global:visit': 'Visit',
    'global:whatsapp': 'WhatsApp',
    'global:with': 'With',
    'global:waitingAdmin': 'Waiting Admin Approval',
    'global:yes': 'Yes',
    'global:yesDelete': 'Yes, Delete',
    'global:yesImSure': "I'm Sure",
    'global:yesterday': 'Yesterday',
    'global:yuser': 'Yuser',
    'global:yuser_plural': 'Yusers',
  },
}
