import {GIFT_SHOP_PROFILE_ROUTE, IMAGE_ASSET} from 'consts'
import {
  GiftShopAddBankAccountScreen,
  GiftShopBankListScreen,
  GiftShopCartDetailScreen,
  GiftShopCartScreen,
  GiftShopEditProfileScreen,
  GiftShopExploreDetailScreen,
  GiftShopExploreScreen,
  GiftShopMyBalanceScreen,
  GiftShopRoboPetScreen,
  GiftShopUploadScreen,
  GiftShopEditProfileResetPasswordScreen,
  GiftShopProfilePostDetailScreen,
  GiftShopProfileScreen,
  GiftShopSettingScreen,
  GiftShopLanguageSettingScreen,
  GiftShopRoboyuSettingScreen,
  GiftShopProfileCreationDetailScreen,
  GiftShopFeedScreen,
  GiftShopMessageScreen,
  GiftShopMessageRoomScreen,
  TransactionDetailScreen,
  GiftshopTransactionHistoryScreen,
  TransactionPaymentScreen,
  TransactionPendingContentDetailScreen,
  TransactionEwalletPaymentScreen,
  TransactionWaitingDetailScreen,
  LegalPageScreen,
  LegalPageScreenTest,
  TemplateSimilarIdentityScreen,
  GiftShopProfileCreationScreen,
  GiftShopCompressSettingScreen,
  GiftshopTransactionWithdrawScreen,
  GiftShopProfileResendBatchScreen,
  GiftShopProfileUpgradeCreatorScreen,
  GiftShopManageAccountScreen,
  GiftShopDeleteAccountSettingScreen,
  GiftShopCollectionScreen,
  GiftShopCollectionDetailScreen,
  GiftShopBlockedAccountSettingScreen,
  GiftShopSendToFaceScreen,
  GiftShopProfileSearchCreationScreen,
  GIftShopSettingWholeSaleScreen,
  GiftShopRecoverContentScreen,
  GiftShopProfileResendSingleScreen,
  GIftShopSettingHostScreen,
  GiftShopCollectorResendApprovalScreen,
  GiftShopResendRevisionScreen,
  GiftShopErrorNotFoundScreen,
  GiftShopRobopetActivationSelfieScreen,
  GiftShopProfileResendVideoScreen,
  GiftShopSimilarChooseAccountScreen,
  GiftShopFavoriteContentScreen,
  GiftShopFavoriteDetailScreen,
  GiftShopArchiveScreen,
  GiftShopSearchByCreatorContentScreen,
  GiftShopSearchByCreatorCreationContentScreen,
  GiftShopSearchByCreatorCreationContentDetailScreen,
  GiftShopRobopetRetakeScreen,
  GiftShopRobopetRetakeSelfieScreen,
  GiftShopSettingShareScreen,
  GiftShopWaitingForPaymentScreen,
  GiftShopSearchCreatorAndFotoTreeScreen,
  GiftShopRobopetChildReapplyScreen,
  GiftShopRobopetChildWefieScreen,
} from 'pages'
import {PaymentModal} from 'common/components'
import {RouteMap} from '../RouteMap'
import {RouteParamGiftShop} from './RouteParamGiftShop'

export const ROUTE_MAP_GIFT_SHOP: RouteMap<RouteParamGiftShop> = {
  giftshop_add_bank_account: {
    component: GiftShopAddBankAccountScreen,
    path: '/banks/add',
    permission: 'user',
  },
  giftshop_archive: {
    component: GiftShopArchiveScreen,
    path: '/archive',
    permission: 'user',
  },
  giftshop_balance: {
    component: GiftShopMyBalanceScreen,
    path: '/balance',
    permission: 'user',
  },
  giftshop_cart: {
    component: GiftShopCartScreen,
    path: '/cart',
    permission: 'user',
  },
  giftshop_cart_detail: {
    component: GiftShopCartDetailScreen,
    path: '/cart/detail',
    defaultState: {
      data: [],
      selectedItemId: '',
    },
    permission: 'user',
  },
  giftshop_checkout: {
    component: PaymentModal,
    path: '/checkout',
    defaultState: null,
    permission: 'user',
  },
  giftshop_collection: {
    component: GiftShopCollectionScreen,
    path: '/collection',
    permission: 'user',
  },
  giftshop_collection_detail: {
    component: GiftShopCollectionDetailScreen,
    path: '/collection/detail',
    defaultState: {
      data: [],
      selectedItemId: '',
    },
    permission: 'user',
  },
  giftshop_edit_profile: {
    component: GiftShopEditProfileScreen,
    path: '/settings/profile',
    defaultState: {menu: 'profile'},
    permission: 'user',
  },
  giftshop_explore: {
    component: GiftShopExploreScreen,
    path: '/fotoyu',
  },
  giftshop_explore_detail: {
    component: GiftShopExploreDetailScreen,
    path: '/fotoyu/detail',
    defaultState: {
      data: [],
      selectedItemId: '',
      isFilter: false,
      isPaginated: false,
    },
    permission: 'user',
  },
  giftshop_favorite: {
    component: GiftShopFavoriteContentScreen,
    path: '/favorite',
    permission: 'user',
  },
  giftshop_favorite_detail: {
    component: GiftShopFavoriteDetailScreen,
    path: '/favorite/detail',
    defaultState: {
      data: [],
      selectedItemId: '',
    },
    permission: 'user',
  },
  giftshop_feed: {
    component: GiftShopFeedScreen,
    exact: true,
    path: '/',
  },
  giftshop_collector_resend_approval: {
    component: GiftShopCollectorResendApprovalScreen,
    path: '/resend-approval',
  },
  giftshop_legal: {
    component: LegalPageScreen,
    path: '/how-it-works',
  },
  giftshop_legal_test: {
    component: LegalPageScreenTest,
    path: '/how-it-works-test',
    permission: 'user',
  },
  giftshop_message: {
    path: '/message',
    component: GiftShopMessageScreen,
    permission: 'user',
  },
  giftshop_message_room: {
    path: '/message/room',
    component: GiftShopMessageRoomScreen,
    defaultState: null,
    permission: 'user',
  },
  giftshop_payment_history: {
    component: TransactionPaymentScreen,
    path: '/payment/history',
    defaultState: null,
    permission: 'user',
  },
  giftshop_ewallet_payment_history: {
    component: TransactionEwalletPaymentScreen,
    path: '/payment/ewallet-history',
    defaultState: null,
    permission: 'user',
  },
  giftshop_page_not_found: {
    component: GiftShopErrorNotFoundScreen,
    path: '/404',
  },
  giftshop_profile: {
    path: `${GIFT_SHOP_PROFILE_ROUTE}/:username`,
    component: GiftShopProfileScreen,
    defaultState: {
      self: false,
    },
  },
  giftshop_profile_creation_group: {
    path: '/profile/:username/creation/date',
    component: GiftShopProfileCreationScreen,
    defaultState: {
      date: '',
      folderName: '',
      availableCount: 0,
      folderPhotoCount: 0,
    },
    permission: 'user',
  },
  giftshop_profile_creation_detail: {
    path: '/profile/:username/creation',
    component: GiftShopProfileCreationDetailScreen,
    defaultState: {
      data: [],
      selectedItemId: '',
      creationType: 'all',
      date: '',
    },
    permission: 'user',
  },
  giftshop_profile_post_detail: {
    path: '/profile/:username/post',
    component: GiftShopProfilePostDetailScreen,
    defaultState: {
      data: [],
      selectedItemId: '',
      self: false,
      memberId: '',
    },
  },
  giftshop_profile_resend_batch: {
    path: '/profile/:username/resend/batch',
    component: GiftShopProfileResendBatchScreen,
    defaultState: null,
    permission: 'user',
  },
  giftshop_profile_resend_single: {
    path: '/profile/:username/resend/single',
    component: GiftShopProfileResendSingleScreen,
    defaultState: null,
    permission: 'user',
  },
  giftshop_profile_resend_video: {
    path: '/profile/:username/resend/video',
    component: GiftShopProfileResendVideoScreen,
    defaultState: null,
    permission: 'user',
  },
  giftshop_profile_search_creation: {
    path: '/profile/:username/creation/search',
    component: GiftShopProfileSearchCreationScreen,
    permission: 'user',
  },
  giftshop_recover_content: {
    path: '/recover-content',
    component: GiftShopRecoverContentScreen,
    permission: 'user',
  },
  giftshop_resend_revision: {
    path: '/resend-revision',
    component: GiftShopResendRevisionScreen,
    defaultState: {
      collectorId: '',
      collectorNickname: '',
      contentId: '',
      hiresUrl: '',
      invoiceId: '',
      originalAt: '',
      originalUrl: '',
      title: '',
      contentType: 'photo',
    },
  },
  giftshop_reset_password: {
    component: GiftShopEditProfileResetPasswordScreen,
    path: '/settings/profile/reset',
    permission: 'user',
  },
  giftshop_robopet: {
    component: GiftShopRoboPetScreen,
    defaultState: {},
    path: '/roboyu',
  },
  giftshop_robopet_activation_selfie: {
    component: GiftShopRobopetActivationSelfieScreen,
    path: '/roboyu/activation',
    defaultState: {},
  },
  giftshop_robopet_incomplete_child: {
    component: GiftShopRobopetChildReapplyScreen,
    path: '/roboyu/underage',
    defaultState: null,
  },
  giftshop_robopet_incomplete_child_wefie: {
    component: GiftShopRobopetChildWefieScreen,
    path: '/roboyu/wefie',
    defaultState: null,
  },
  giftshop_robopet_retake: {
    component: GiftShopRobopetRetakeScreen,
    path: '/roboyu/retake',
  },
  giftshop_robopet_retake_selfie: {
    component: GiftShopRobopetRetakeSelfieScreen,
    path: '/roboyu/retake/selfie',
  },
  giftshop_search_by_creator: {
    component: GiftShopSearchByCreatorContentScreen,
    path: '/search-creator',
    permission: 'user',
  },
  giftshop_search_by_creator_creation_content: {
    component: GiftShopSearchByCreatorCreationContentScreen,
    path: '/search-creator/creation',
    defaultState: {
      photo: IMAGE_ASSET('giftshop', 'users/default.png'),
      bio: '',
      shouldFetchData: false,
    },
    permission: 'user',
  },
  giftshop_search_by_creator_creation_content_detail: {
    component: GiftShopSearchByCreatorCreationContentDetailScreen,
    path: '/search-creator/creation/detail',
    defaultState: {
      data: [],
      selectedItemId: '',
      creatorId: '',
    },
  },
  giftshop_search_creator_and_fototree: {
    component: GiftShopSearchCreatorAndFotoTreeScreen,
    path: '/search/creator-and-fototree',
    permission: 'user',
  },
  giftshop_send_to_face: {
    component: GiftShopSendToFaceScreen,
    path: '/send-to-face',
    defaultState: {
      files: [],
    },
    permission: 'user',
  },
  giftshop_setting: {
    path: '/setting',
    component: GiftShopSettingScreen,
    permission: 'user',
  },
  giftshop_setting_blocked_account: {
    path: '/setting/manage-account/blocked-account',
    component: GiftShopBlockedAccountSettingScreen,
    permission: 'user',
  },
  giftshop_setting_compress: {
    path: '/setting/compress',
    component: GiftShopCompressSettingScreen,
    permission: 'user',
  },
  giftshop_setting_delete_account: {
    path: '/setting/manage-account/delete-account',
    component: GiftShopDeleteAccountSettingScreen,
    permission: 'user',
  },
  giftshop_setting_host: {
    path: '/setting/host',
    component: GIftShopSettingHostScreen,
    permission: 'user',
  },
  giftshop_setting_language: {
    path: '/setting/language',
    component: GiftShopLanguageSettingScreen,
    permission: 'user',
  },
  giftshop_setting_manage_account: {
    path: '/setting/manage-account',
    component: GiftShopManageAccountScreen,
    permission: 'user',
  },
  giftshop_setting_roboyu: {
    path: '/setting/roboyu',
    component: GiftShopRoboyuSettingScreen,
    permission: 'user',
  },
  giftshop_setting_share: {
    path: '/setting/share',
    component: GiftShopSettingShareScreen,
    permission: 'user',
  },
  giftshop_setting_wholesale: {
    path: '/setting/wholesale',
    component: GIftShopSettingWholeSaleScreen,
    permission: 'user',
  },
  giftshop_similar_identity: {
    path: '/similar-identity',
    component: TemplateSimilarIdentityScreen,
    permission: 'user',
    defaultState: {},
  },
  giftshop_similar_choose_account: {
    path: '/similar-choose-account',
    component: GiftShopSimilarChooseAccountScreen,
    permission: 'user',
  },
  giftshop_transaction_detail: {
    component: TransactionDetailScreen,
    path: '/transaction/detail',
    defaultState: null,
    permission: 'user',
  },
  giftshop_waiting_for_payment_detail: {
    component: TransactionWaitingDetailScreen,
    path: '/transaction/waiting/detail',
    defaultState: null,
    permission: 'user',
  },
  giftshop_transaction_history: {
    component: GiftshopTransactionHistoryScreen,
    path: '/transaction/history',
    permission: 'user',
  },
  giftshop_transaction_pending_content_detail: {
    path: '/transaction/content/detail',
    component: TransactionPendingContentDetailScreen,
    defaultState: null,
    permission: 'user',
  },
  giftshop_upgrade_creator: {
    component: GiftShopProfileUpgradeCreatorScreen,
    path: '/upgrade',
    permission: 'user',
  },
  giftshop_upload: {
    component: GiftShopUploadScreen,
    path: '/upload',
    permission: 'user',
  },
  giftshop_user_bank_list: {
    component: GiftShopBankListScreen,
    path: '/banks/list',
    permission: 'user',
  },
  giftshop_withdraw: {
    component: GiftshopTransactionWithdrawScreen,
    path: '/withdraw',
    permission: 'user',
  },
  giftshop_waiting_for_payment: {
    component: GiftShopWaitingForPaymentScreen,
    path: '/waiting/list',
    permission: 'user',
  },
}
