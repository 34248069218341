import React, {useCallback, useMemo, useState} from 'react'
import styled, {useTheme} from 'styled-components'
import {useTranslation} from 'i18n'
import {AUTH_RESEND_COOLDOWN_MINUTE, IMAGE_ASSET, OTP_COOLDOWN} from 'consts'
import {
  formatCooldownTime,
  formatPhoneNumber,
  getFontFamily,
  maskPhone,
  showSnackbar,
  useHistory,
  useLocation,
  useTimer,
} from 'utils'
import {requestData} from 'services'
import {AuthPhoneResendMethod, parseShape} from 'types'
import {Image, Paragraph, ParsedText} from 'common/components'
import convertUnit from 'lib/unit'
import {useDispatch, useSelector} from 'lib/redux'
import {
  TemplateAuthAccessResendOtpModal,
  TemplateAuthEasySignupChangeCredentialModal,
} from '../../../template'

const StyledContentContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`

const StyledHeaderDescription = styled(Paragraph)`
  margin-top: ${convertUnit(10)};
  text-align: center;
`

const StyledImgContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${convertUnit(20)} 0;
`

const StyledIllustration = styled(Image)`
  max-width: ${convertUnit(325)};
  width: 100%;
`

const StyledParagraph = styled(Paragraph)`
  text-align: center;
`

const StyledResendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface AuthEasySignupVerificationPhoneProps {
  shouldCensor?: boolean
  showResend?: boolean
}
export default function AuthEasySignupVerificationPhone({
  shouldCensor = true,
  showResend = true,
}: AuthEasySignupVerificationPhoneProps) {
  const {translate} = useTranslation()
  const theme = useTheme()
  const history = useHistory()
  const {
    method,
    phone_code,
    phone_number,
    identifier,
    memberId,
    tagId,
    treeId,
  } = useLocation('auth_easy_signup_verification').state
  const whatsappConfirmImage = IMAGE_ASSET('giftshop', 'whatsapp-confirm.png')
  const phoneConfirmImage = IMAGE_ASSET('giftshop', 'mobile-confirm.png')
  const {showChangeCredentialButton} = useSelector('easySignupState')
  const {update} = useDispatch()
  const stateSelectedMethod = useState<AuthPhoneResendMethod>(
    method as AuthPhoneResendMethod,
  )
  const selectedMethod = stateSelectedMethod[0]
  const [image, setImage] = useState<AuthPhoneResendMethod>(
    method as AuthPhoneResendMethod,
  )
  const stateLoading = useState(false)
  const setLoading = stateLoading[1]
  const stateShowChangeCredentialModal = useState(false)
  const setShowChangeCredentialModal = stateShowChangeCredentialModal[1]
  const stateChangeCredentialError = useState('')
  const setChangeCredentialError = stateChangeCredentialError[1]

  const {phoneOTPTime} = useSelector('lastUserState')
  const phoneMask =
    phone_code && phone_number ? maskPhone(phone_code, phone_number) : ''
  const phoneDash =
    phone_code && phone_number
      ? `+${phone_code} ${formatPhoneNumber(phone_number)}`
      : ''
  const [isVisible, setIsVisible] = useState(false)
  const [isLimit, setIsLimit] = useState(false)

  const today = new Date().getTime() / 1000
  const {countdown, reset} = useTimer({
    duration:
      phoneOTPTime && today - phoneOTPTime / 1000 <= OTP_COOLDOWN
        ? OTP_COOLDOWN - (today - phoneOTPTime / 1000)
        : OTP_COOLDOWN,
    countOnStart: true,
  })

  const countDownMinute = Math.floor(countdown / 60)
  const countDownSecond = countdown - 60 * countDownMinute

  const handleUpdateCredential = useCallback(
    (credential: string) => {
      setLoading(true)
      requestData('auth_easy_signup_update_phone', {
        data: {
          phone_code: credential.split('-')[0],
          phone_number: credential.split('-')[1],
          member_id: memberId,
          tag_id: tagId,
          tree_id: treeId,
        },
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            update('lastUserState', {
              phoneOTPTime: new Date().getTime(),
            })
            update('easySignupState', {
              showChangeCredentialButton: false,
            })
            setShowChangeCredentialModal(false)
            reset(OTP_COOLDOWN)
            showSnackbar(
              translate(
                'auth:registerEasySignupUpdateCredentialSnackbar_whatsapp',
              ),
            )
            history.replace('auth_easy_signup_verification', {
              method: 'whatsapp',
              phone_code: credential.split('-')[0],
              phone_number: credential.split('-')[1],
              shouldCensor: false,
              memberId,
              tagId,
              treeId,
            })
          } else if (status === 429) {
            setChangeCredentialError(
              translate('auth:forgotPasswordResendMessage', {
                cooldown: AUTH_RESEND_COOLDOWN_MINUTE,
              }),
            )
          } else {
            setChangeCredentialError(
              translate('global:messageError', {context: `code-${status}`}),
            )
          }
        },
      })
      setLoading(false)
    },
    [
      history,
      memberId,
      reset,
      setChangeCredentialError,
      setLoading,
      setShowChangeCredentialModal,
      tagId,
      translate,
      treeId,
      update,
    ],
  )

  const handleResend = useCallback(() => {
    requestData('auth_easy_signup_resend', {
      data: {
        identifier: identifier || `${phone_code}${phone_number}`,
        method: selectedMethod,
      },
      onRequestSuccess: ({status, data}) => {
        if (status === 200) {
          reset(OTP_COOLDOWN)
          update('lastUserState', {
            phoneOTPTime: new Date().getTime(),
          })
        } else if (status === 429) {
          const errorDetail = data.result
          if (errorDetail.is_limit) {
            setIsLimit(true)
          }
          const {hour, minute, second} = formatCooldownTime(
            errorDetail.resend_in,
          )
          showSnackbar(
            translate(
              errorDetail.is_limit
                ? 'auth:registerEasySignupResendLinkLimitSnackbar'
                : 'auth:registerEasySignupResendLinkCooldownSnackbar',
              {
                hour,
                minute,
                second,
              },
            )
              .replace(
                hour === ''
                  ? /<hourStamp>.*?<\/hourStamp>/
                  : /<hourStamp>|<\/hourStamp>/g,
                '',
              )
              .replace(
                minute === ''
                  ? /<minuteStamp>.*?<\/minuteStamp>/
                  : /<minuteStamp>|<\/minuteStamp>/g,
                '',
              )
              .replace(/<secondStamp>|<\/secondStamp>/g, ''),
            [
              {
                pattern: /<timestamp>.*?<\/timestamp>/,
                style: {
                  fontFamily: 'Roboto-Bold',
                },
                renderText: (text) =>
                  text.replace(/<timestamp>|<\/timestamp>/g, ''),
              },
            ],
          )
        } else {
          showSnackbar(
            translate('global:messageError', {context: `code-${status}`}),
          )
        }
      },
    })
  }, [
    identifier,
    phone_code,
    phone_number,
    reset,
    selectedMethod,
    translate,
    update,
  ])

  const handleSelectResendMethod = useCallback(
    (selected: AuthPhoneResendMethod) => {
      setImage(selected)
      setIsVisible(false)
      handleResend()
    },
    [handleResend],
  )

  const parseText: parseShape[] = useMemo(
    () => [
      {
        pattern: /<resend>.*?<\/resend>/,
        style: {
          color: theme.primary_5,
          cursor: 'pointer',
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<resend>|<\/resend>/g, ''),
        onClick: () => !isLimit && setIsVisible(true),
      },
      {
        pattern: /<cooldown>.*?<\/cooldown>/,
        style: {
          color: theme.gray_3,
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<cooldown>|<\/cooldown>/g, ''),
      },
      {
        pattern: /<change>.*?<\/change>/,
        style: {
          color: theme.primary_5,
          cursor: 'pointer',
          fontFamily: getFontFamily('bold'),
        },
        renderText: (str) => str.replace(/<change>|<\/change>/g, ''),
        onClick: () => setShowChangeCredentialModal(true),
      },
    ],
    [isLimit, setShowChangeCredentialModal, theme.gray_3, theme.primary_5],
  )

  const handleRenderResend = useMemo(
    () => (
      <StyledResendContainer>
        <StyledParagraph color="gray_5">
          {translate('auth:verificationWhatsappDidNotGet')}
          <ParsedText parse={parseText}>
            {countdown === 0
              ? ` <resend>${translate(
                  'auth:forgotPasswordPhoneResend',
                )}</resend>`
              : ` <cooldown>${translate(
                  'auth:forgotPasswordPhoneResendCountdown',
                  {
                    countdown:
                      countdown > 60
                        ? `${countDownMinute}m${countDownSecond}`
                        : `${countDownSecond}`,
                  },
                )}</cooldown>`}
          </ParsedText>
          {showChangeCredentialButton && !!memberId ? (
            <ParsedText parse={parseText}>
              {translate('auth:verificationChangeCredential', {
                context: 'phone',
              })}
            </ParsedText>
          ) : (
            <></>
          )}
        </StyledParagraph>
      </StyledResendContainer>
    ),
    [
      countDownMinute,
      countDownSecond,
      countdown,
      memberId,
      parseText,
      showChangeCredentialButton,
      translate,
    ],
  )

  const handleRenderTitle = useMemo(
    () => (
      <>
        <StyledParagraph fontSize="xl" fontWeight="bold">
          {shouldCensor ? phoneMask : phoneDash}
        </StyledParagraph>
        <StyledHeaderDescription fontSize="m" color="gray_5">
          {translate('auth:registerEasySignupVerifMobileHeaderDescription', {
            context: image,
          })}
        </StyledHeaderDescription>
      </>
    ),
    [image, phoneDash, phoneMask, shouldCensor, translate],
  )

  const handleRenderMidSection = useMemo(
    () => (
      <>
        <StyledImgContainer>
          <StyledIllustration
            src={
              image === 'whatsapp' ? whatsappConfirmImage : phoneConfirmImage
            }
            alt="Illustration"
          />
        </StyledImgContainer>
        {showResend ? handleRenderResend : <></>}
      </>
    ),
    [
      handleRenderResend,
      image,
      phoneConfirmImage,
      showResend,
      whatsappConfirmImage,
    ],
  )

  const handleRenderChangePhoneModal = useMemo(
    () => (
      <TemplateAuthEasySignupChangeCredentialModal
        stateModal={stateShowChangeCredentialModal}
        method="phone"
        currentCredential={`${phone_code}-${phone_number}`}
        stateLoading={stateLoading}
        onCancel={() => setShowChangeCredentialModal(false)}
        onConfirm={(cred) => handleUpdateCredential(cred)}
        stateError={stateChangeCredentialError}
      />
    ),
    [
      handleUpdateCredential,
      phone_code,
      phone_number,
      setShowChangeCredentialModal,
      stateChangeCredentialError,
      stateLoading,
      stateShowChangeCredentialModal,
    ],
  )

  return (
    <StyledContentContainer>
      <TemplateAuthAccessResendOtpModal
        isVisible={isVisible}
        stateSelectedMethod={stateSelectedMethod}
        onConfirm={handleSelectResendMethod}
        onCancel={() => setIsVisible(false)}
        userInfo={{phoneCode: phone_code!, phoneNumber: phone_number!}}
      />
      {handleRenderChangePhoneModal}
      {handleRenderTitle}
      {handleRenderMidSection}
    </StyledContentContainer>
  )
}
