import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useForm} from 'react-hook-form'
import styled from 'styled-components'
import {AUTH_COUNTRY_DEFAULT, REGEX_PHONE_NUMBER} from 'consts'
import {useTranslation} from 'i18n'
import {requestData} from 'services'
import {
  AuthCountryResponse,
  GiftshopEditProfileResendMethodType,
  GiftshopProfileVerifyPassword,
  PhoneForm,
} from 'types'
import {showPrompt, showSnackbar, useDidMount, useHistory} from 'utils'
import {Button, Input, InputPhone} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {
  VALIDATION_AUTH_LOGIN_PASSWORD,
  VALIDATION_AUTH_PHONE,
} from 'common/validations'
import {GiftShopPhoneFormProps} from './GiftShopFormProps'
import {
  GiftShopEditProfileLimitPasswordModal,
  GiftShopEditProfilePasswordVerifyModal,
  GiftShopEditProfilePhoneResendModal,
  GiftShopEditProfileSuccessStatus,
  GiftShopEditProfileVerifyOTPModal,
} from '../Modal'

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${convertUnit(20)};
`

const StyledScreenContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${convertUnit(20)} auto;
  padding: ${convertUnit(40)};
  border-radius: ${convertUnit(16)};
  background-color: ${({theme}) => theme.white_1};
  max-width: ${convertUnit(561)};
  height: max-content;
`

export default function GiftshopPhoneForm({
  stateActiveContent,
  statePhoneModal,
}: GiftShopPhoneFormProps) {
  const setActiveContent = stateActiveContent[1]
  const {update} = useDispatch()
  const {translate} = useTranslation()
  const history = useHistory()
  const {has_password} = useSelector('user') || {}
  const stateCountry = useState(AUTH_COUNTRY_DEFAULT)
  const [modalState, setModalState] = statePhoneModal
  const country = stateCountry[0]
  const successfulVerificationRef = useRef(true)
  const {verificationType} = useSelector('verificationStatus')
  const {phoneNumber, phoneCode} = useSelector('userNewPhone') || {}

  const form = useForm<PhoneForm>({
    defaultValues: {
      phone: verificationType === 'add_phone' ? phoneNumber : '',
      password: '',
    },
  })
  const {handleSubmit, watch, errors, setError} = form
  const {phone, password} = watch()

  const [loading, setLoading] = useState(false)
  const [publishedState, setPublishedState] = useState(false)
  const [cooldownTimer, setCooldownTimer] = useState('0')
  const resendMethodState = useState<GiftshopEditProfileResendMethodType>(
    'whatsapp',
  )
  const resendMethod = resendMethodState[0]
  const [passwordVerifyModal, setPasswordVerifyModal] = useState<{
    visible: boolean
    item: GiftshopProfileVerifyPassword
  }>({
    visible: false,
    item: 'none',
  })

  const handleLoadDataPhoneDummy = useCallback(
    (search: string): AuthCountryResponse[] =>
      !search || 'indonesia'.includes(search.toLowerCase())
        ? [AUTH_COUNTRY_DEFAULT]
        : [],
    [],
  )

  const handlePasswordVerifyModal = useCallback(
    (item: GiftshopProfileVerifyPassword) =>
      setPasswordVerifyModal((prev) => ({visible: !prev.visible, item})),
    [],
  )

  const addPhone = useCallback(async () => {
    if (phoneNumber === phone) {
      setModalState('verify')
    } else {
      setLoading(true)
      await requestData('giftshop_post_phone', {
        actionType: 'execute',
        data: {
          phone_code: country.dial_code,
          phone_number: phone,
          password,
        },
        onRequestReceived: () => {
          setPublishedState(true)
          setLoading(false)
        },
        onRequestSuccess: ({data: {detail, message}, status}) => {
          if (status >= 200 && status < 300) {
            update('userNewPhone', {
              phoneCode: country.dial_code,
              phoneNumber: phone,
            })
            update('verificationStatus', {
              verificationType: 'add_phone',
              isPhoneVerified: false,
            })
            update('lastUserState', {
              phoneOTPTime: new Date().getTime(),
            })
            setModalState('verify')
          } else if (
            status === 400 &&
            message === 'password invalid' &&
            typeof detail !== 'undefined'
          ) {
            setError('password', {
              type: 'manual',
              message: translate('auth:validationLoginAttempt', {
                attempt: detail.attempt,
              }),
            })
          } else if (status === 409 && message === 'phone exist') {
            setError('phone', {
              type: 'manual',
              message: translate('auth:validationPhoneRegistered'),
            })
          } else if (status === 422 && message === 'password does not exist') {
            handlePasswordVerifyModal('phone')
          } else if (status === 423 && typeof detail !== 'undefined') {
            setCooldownTimer(detail.wait_time)
            setModalState('limit')
          } else if (status === 429 && typeof detail !== 'undefined') {
            showSnackbar(
              translate('auth:resendLimitReached', {
                duration: detail.wait_time,
              }),
            )
          } else {
            successfulVerificationRef.current = false
            setModalState('confirmation')
          }
        },
      })
    }
  }, [
    country.dial_code,
    handlePasswordVerifyModal,
    password,
    phone,
    phoneNumber,
    setError,
    setModalState,
    translate,
    update,
  ])

  const resendPhoneOTP = useCallback(
    (method: GiftshopEditProfileResendMethodType) => {
      requestData('giftshop_patch_resend_phone', {
        data: {type: method},
        onRequestSuccess: ({data: {detail}, status}) => {
          if (status === 200) {
            update('lastUserState', {
              phoneOTPTime: new Date().getTime(),
            })
            setModalState('verify')
          } else if (status === 429 && typeof detail !== 'undefined') {
            showSnackbar(
              translate('auth:resendLimitReached', {
                duration: detail.wait_time,
              }),
            )
          } else {
            successfulVerificationRef.current = false
            setModalState('confirmation')
          }
        },
      })
    },
    [update, setModalState, translate],
  )

  const handleRenderPasswordVerifyModal = useMemo(
    () => (
      <GiftShopEditProfilePasswordVerifyModal
        visible={passwordVerifyModal.visible}
        item={passwordVerifyModal.item}
        onCancel={() => {
          handlePasswordVerifyModal('none')
          history.goBack()
        }}
        onConfirm={() => setActiveContent('password')}
      />
    ),
    [
      handlePasswordVerifyModal,
      history,
      passwordVerifyModal.item,
      passwordVerifyModal.visible,
      setActiveContent,
    ],
  )

  const isButtonDisabled = useMemo(() => {
    const isValidPhone = errors.phone !== undefined || phone === ''
    const isValidPassword = errors.password !== undefined || password === ''

    return isValidPhone || isValidPassword
  }, [errors.password, errors.phone, password, phone])

  const handleRenderPhoneField = useMemo(
    () => (
      <InputPhone
        name="phone"
        loadData={handleLoadDataPhoneDummy}
        stateSelect={stateCountry}
        form={form}
        formRules={VALIDATION_AUTH_PHONE}
        allowedCharacters={REGEX_PHONE_NUMBER}
        emptyMessage={(search) =>
          translate('auth:registerIdentifierSearchCountryEmpty', {
            search,
          })
        }
        maxLength={13}
        label={translate('global:mobileNumberNew')}
        placeholder={translate('auth:registerIdentifierInputPhonePlaceholder')}
        searchPlaceholder={translate('global:search')}
        autoComplete={false}
        rightIcon="delete"
      />
    ),
    [form, handleLoadDataPhoneDummy, stateCountry, translate],
  )

  const handleRenderPasswordField = useMemo(
    () => (
      <Input
        type="password"
        name="password"
        form={form}
        formRules={VALIDATION_AUTH_LOGIN_PASSWORD}
        label={translate('global:password')}
        placeholder={translate('auth:loginInputPasswordPlaceholder')}
        rightIcon="visibility"
        containerStyle={{marginTop: convertUnit(20)}}
      />
    ),
    [form, translate],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledButton
        disabled={isButtonDisabled}
        isLoading={loading}
        label={translate('global:save')}
        onClick={handleSubmit(() => {
          addPhone()
        })}
      />
    ),
    [isButtonDisabled, loading, translate, handleSubmit, addPhone],
  )

  const handleRenderModal = useMemo(() => {
    switch (modalState) {
      case 'verify':
        return (
          <GiftShopEditProfileVerifyOTPModal
            verificationMethod={resendMethod}
            onCancel={() => setModalState('hidden')}
            onSuccess={() => {
              successfulVerificationRef.current = true
              update('verificationStatus', {
                verificationType: 'none',
                isPhoneVerified: true,
              })
              update('user', {
                phone_code: phoneCode,
                phone_number: phoneNumber,
              })
              update('userNewPhone', {
                phoneCode: '',
                phoneNumber: '',
              })
              setModalState('confirmation')
            }}
            onResend={() => setModalState('resend')}
            onFailed={() => {
              successfulVerificationRef.current = false
              setModalState('confirmation')
            }}
          />
        )
      case 'confirmation':
        return (
          <GiftShopEditProfileSuccessStatus
            success={successfulVerificationRef.current}
            desc={translate(
              successfulVerificationRef.current
                ? 'auth:verificationPhoneConfirmHeaderDescription'
                : 'giftShop:problemWhenProcessingRequest',
            )}
            onClickButton={() => {
              setModalState('hidden')
              if (successfulVerificationRef.current) {
                setActiveContent('profile')
              }
            }}
          />
        )
      case 'limit':
        return (
          <GiftShopEditProfileLimitPasswordModal
            cooldownTimer={cooldownTimer}
            onConfirm={() => setModalState('hidden')}
          />
        )
      case 'resend':
        return (
          <GiftShopEditProfilePhoneResendModal
            stateVerificationMethod={resendMethodState}
            onCancel={() => setModalState('hidden')}
            onSelect={(method) => resendPhoneOTP(method)}
          />
        )
      default:
        return null
    }
  }, [
    cooldownTimer,
    modalState,
    phoneCode,
    phoneNumber,
    resendMethod,
    resendMethodState,
    resendPhoneOTP,
    setActiveContent,
    setModalState,
    translate,
    update,
  ])

  useEffect(() => {
    const prompt = (!!phone || !!password) && !publishedState
    showPrompt(prompt, translate('giftShop:uploadModalMessage'))
  }, [password, phone, publishedState, translate])

  useDidMount(() => {
    if (!has_password) {
      handlePasswordVerifyModal('phone')
    }
  })

  return (
    <StyledScreenContainer>
      {handleRenderModal}
      {handleRenderPasswordVerifyModal}
      {handleRenderPhoneField}
      {handleRenderPasswordField}
      {handleRenderButton}
    </StyledScreenContainer>
  )
}
