import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {translate} from 'i18n'
import {GiftShopCreationSortByType, WindowModeType} from 'types'
import {useHistory} from 'utils'
import {useWindowMode} from 'windows'
import {Button, Icon} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {TemplateTabListHeaderCreationProps} from './TemplateTabListHeaderCreationProps'
import {TemplateProfileCreationChip} from '../CreationChip'
import {TemplateCreationSortModal} from '../SortModal'

interface StyledButtonContainerProps {
  mode: WindowModeType
}

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: ${convertUnit(30)};
  padding: ${convertUnit(8)} ${convertUnit(20)};
  background-color: ${({theme}) => theme.white_1};
  overflow-x: auto;
`

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledButtonContainer = styled.div<StyledButtonContainerProps>`
  ${({mode}) => ({
    paddingRight: mode === 'mobile' ? convertUnit(16) : convertUnit(4),
  })}
  border-left: solid 2px ${({theme}) => theme.white_4};
`

const StyledSearchButton = styled(Button)<StyledButtonContainerProps>`
  ${({mode}) => ({
    paddingLeft: mode === 'mobile' ? convertUnit(16) : convertUnit(20),
    paddingRight: mode === 'mobile' ? convertUnit(0) : convertUnit(20),
  })}
  :hover {
    opacity: 0.6;
  }
`

const StyledRightButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export default function TemplateTabListHeaderCreation({
  resendCount,
  resendpologyCount,
  stateCreationType,
  disabled,
  isHost = false,
}: TemplateTabListHeaderCreationProps) {
  const mode = useWindowMode()
  const {username} = useSelector('user') || {}
  const {update} = useDispatch()
  const history = useHistory()
  const creationType = stateCreationType[0]
  const [modalVisible, setModalVisible] = useState(false)

  const handleOnClickSort = useCallback(
    (currSortBy: GiftShopCreationSortByType) => {
      update('giftshopState', {
        resendSortType: currSortBy,
      })
    },
    [],
  )

  const handleRenderButton = useMemo(
    () => (
      <StyledRightButtonContainer>
        {creationType === 'resend' && (
          <StyledSearchButton
            mode={mode}
            label={mode === 'mobile' ? '' : translate('global:sort')}
            style={{paddingRight: 0}}
            leftIcon={<Icon type="sort" color="primary_5" />}
            color="primary_5"
            backgroundColor="white_1"
            backgroundHoverColor="white_2"
            onClick={() => setModalVisible(true)}
          />
        )}
        <StyledSearchButton
          mode={mode}
          label={mode === 'mobile' ? '' : translate('global:search')}
          leftIcon={<Icon type="search" color="primary_5" />}
          color="primary_5"
          backgroundColor="white_1"
          backgroundHoverColor="white_2"
          onClick={() =>
            history.push('giftshop_profile_search_creation', {}, username)
          }
        />
      </StyledRightButtonContainer>
    ),
    [creationType, history, mode, username],
  )

  return (
    <StyledContainer>
      <StyledItemContainer>
        <TemplateProfileCreationChip
          type="all"
          stateCreationType={stateCreationType}
          disabled={disabled}
        />
        <TemplateProfileCreationChip
          type="available"
          stateCreationType={stateCreationType}
          disabled={disabled}
        />
        <TemplateProfileCreationChip
          type="sold"
          stateCreationType={stateCreationType}
          disabled={disabled}
        />
        <TemplateProfileCreationChip
          type="resend"
          stateCreationType={stateCreationType}
          resendCount={resendCount}
          resendpologyCount={resendpologyCount}
          disabled={disabled}
        />
      </StyledItemContainer>
      <StyledButtonContainer mode={mode}>
        {!isHost && handleRenderButton}
      </StyledButtonContainer>
      <TemplateCreationSortModal
        onClickItem={handleOnClickSort}
        toggleModal={() => {
          setModalVisible(false)
        }}
        visible={modalVisible}
      />
    </StyledContainer>
  )
}
