import {GIFT_SHOP_SENSITIVITY_LEVEL_DEFAULT, IMAGE_ASSET} from 'consts'
import {combineReducers, Action, Reducer} from 'redux'
import {PersistConfig} from 'redux-persist'
import {
  GiftShopExploreFilterStates,
  CartData,
  DuckGiftshopState,
  LastUploadState,
  LastUserState,
  UserBannedStatus,
  UserData,
  GiftShopVerificationState,
  PhoneData,
  TreeHostState,
  TreeHostRegistrationState,
  GiftShopClosedBannerState,
  EmailForm,
  LastPasswordOTPTime,
  YuserActivationState,
  MessageRoomState,
  LastTreeState,
  ForgotPasswordState,
  GiftShopIncompleteKycState,
  UserSimilarAccountStatusPayload,
  GiftShopRoboyuSensitivityLevelState,
  TreeQrState,
  GiftShopTooltipState,
  LanguageState,
  MaintenanceModalStatus,
  AuthEasySignupState,
  AuthEasySignupDuplicateStatus,
  UserDevice,
  ParentalConsentState,
  GiftShopExploreMonthPaginationState,
} from 'types'

export interface ReduxReducerState {
  user: UserData | null
  cart: CartData
  giftshopState: DuckGiftshopState
  lastUserState: LastUserState
  lastUploadStatePhoto: LastUploadState
  lastUploadStateVideo: LastUploadState
  userBanStatus: UserBannedStatus
  exploreFilter: GiftShopExploreFilterStates
  exploreMonthPaginationState: GiftShopExploreMonthPaginationState
  verificationStatus: GiftShopVerificationState
  userNewPhone: PhoneData | null
  userNewEmail: EmailForm | null
  hostState: TreeHostState
  hostRegistrationState: TreeHostRegistrationState
  bannerClosed: GiftShopClosedBannerState
  lastPasswordOTPTime: LastPasswordOTPTime
  lastTreeState: LastTreeState
  yuserActivationState: YuserActivationState
  messageRoomState: MessageRoomState[]
  forgotPasswordState: ForgotPasswordState
  incompleteKycState: GiftShopIncompleteKycState
  similarAccountStatus: UserSimilarAccountStatusPayload
  roboyuSensitivityLevel: GiftShopRoboyuSensitivityLevelState
  treeQrState: TreeQrState
  giftShopTooltipState: GiftShopTooltipState
  languageState: LanguageState
  maintenanceModalStatus: MaintenanceModalStatus
  easySignupDuplicateState: AuthEasySignupDuplicateStatus
  easySignupState: AuthEasySignupState
  userDevice: UserDevice
  parentalConsentState: ParentalConsentState
}

export type ReduxStateKey = keyof ReduxReducerState

export interface ReduxReducerPersistConfig
  extends PersistConfig<ReduxReducerState> {
  whitelist?: ReduxStateKey[]
}

export type ReduxDispatchType = 'update' | 'reset'

export interface ReduxActionBase<K extends ReduxStateKey>
  extends Action<ReduxDispatchType> {
  key?: K
  payload?: Partial<ReduxReducerState[K]>
}

export type ReduxReducerMap = {
  [K in ReduxStateKey]: Reducer<ReduxReducerState[K], ReduxActionBase<K>>
}

export function generateReducer<K extends ReduxStateKey>(
  key: K,
  initialState: ReduxReducerState[K],
): Reducer<ReduxReducerState[K], ReduxActionBase<K>> {
  return (state = initialState, action) => {
    if (action.key !== key) {
      return state
    }

    switch (action.type) {
      case 'update':
        return {...state, ...action.payload}
      case 'reset':
        return initialState
      default:
        return state
    }
  }
}

export const mapReducer: ReduxReducerMap = {
  cart: generateReducer('cart', {cart_count: 0}),
  giftshopState: generateReducer('giftshopState', {
    showCollectionMark: false,
    showFavoriteMark: false,
    resendSortType: 'title',
    resendCount: 0,
    resendpologyCount: 0,
    guidelineCount: 0,
  }),
  user: generateReducer('user', null),
  lastUserState: generateReducer('lastUserState', {
    phoneOTPTime: new Date().getTime(),
    emailTime: new Date().getTime(),
    easySignupCodeTime: new Date().getTime(),
    isTokenExpired: false,
  }),
  lastUploadStatePhoto: generateReducer('lastUploadStatePhoto', {
    description: '',
    price: '0',
    tags: [],
    latitude: '',
    longitude: '',
    name: '',
  }),
  lastUploadStateVideo: generateReducer('lastUploadStateVideo', {
    description: '',
    price: '0',
    tags: [],
    latitude: '',
    longitude: '',
    name: '',
  }),
  userBanStatus: generateReducer('userBanStatus', {
    status: 'free',
    until: '',
  }),
  exploreFilter: generateReducer('exploreFilter', {
    startDate: '',
    endDate: '',
    latitude: '',
    longitude: '',
    tags: [],
    lastUpdated: new Date().toISOString(),
    creators: [],
    filterSensitivity: GIFT_SHOP_SENSITIVITY_LEVEL_DEFAULT,
  }),
  exploreMonthPaginationState: generateReducer(
    'exploreMonthPaginationState',
    {},
  ),
  verificationStatus: generateReducer('verificationStatus', {
    verificationType: 'none',
    isEmailVerified: false,
    isPhoneVerified: true,
  }),
  userNewPhone: generateReducer('userNewPhone', null),
  userNewEmail: generateReducer('userNewEmail', null),
  hostState: generateReducer('hostState', {}),
  hostRegistrationState: generateReducer('hostRegistrationState', {}),
  bannerClosed: generateReducer('bannerClosed', {
    roboyu: false,
    setting: false,
  }),
  lastPasswordOTPTime: generateReducer('lastPasswordOTPTime', {
    phoneOTPTIme: 0,
  }),
  yuserActivationState: generateReducer('yuserActivationState', {
    originalKycPhoto: IMAGE_ASSET('giftshop', 'users/default-portrait.png'),
  }),
  messageRoomState: generateReducer('messageRoomState', []),
  lastTreeState: generateReducer('lastTreeState', {longitude: 0, latitude: 0}),
  forgotPasswordState: generateReducer('forgotPasswordState', {}),
  incompleteKycState: generateReducer('incompleteKycState', {
    shouldShowIncompleteKycModal: true,
  }),
  roboyuSensitivityLevel: generateReducer('roboyuSensitivityLevel', {
    sensitivityLevel: 5,
  }),
  similarAccountStatus: generateReducer('similarAccountStatus', {
    status: 'NONE',
  }),
  treeQrState: generateReducer('treeQrState', {}),
  giftShopTooltipState: generateReducer('giftShopTooltipState', {
    lastPopTime: 0,
    isClickedAccuracy: false,
  }),
  languageState: generateReducer('languageState', {
    lang: 'id',
  }),
  maintenanceModalStatus: generateReducer('maintenanceModalStatus', {
    showMaintenanceModal: true,
  }),
  easySignupDuplicateState: generateReducer('easySignupDuplicateState', {}),
  easySignupState: generateReducer('easySignupState', {
    hasRegistered: false,
    showChangeCredentialButton: false,
  }),
  userDevice: generateReducer('userDevice', {
    device_client_id: null,
    stage: null,
  }),
  parentalConsentState: generateReducer('parentalConsentState', {}),
}

export const REDUX_REDUCER = combineReducers(mapReducer)
