import React, {useCallback, useMemo, useState} from 'react'
import styled from 'styled-components'
import {AUTH_LOGIN_MAX_ATTEMPT} from 'consts'
import {translate} from 'i18n'
import {requestData} from 'services'
import {Button, Input, Modal, Paragraph} from 'common/components'
import {useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {DeleteAccountModalProps} from './DeleteAccountModalProps'

const StyledButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: ${convertUnit(20)};
`

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: ${convertUnit(335)};
  padding: ${convertUnit(20)};
  border-radius: ${convertUnit(16)};
  text-align: center;
`

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${convertUnit(20)};
`

const StyledError = styled(Paragraph)`
  margin-top: ${convertUnit(25)};
`

export default function DeleteAccountModal({
  stateModal,
  statePassword,
  onCancel,
  stateStep,
}: DeleteAccountModalProps) {
  const [password, setPassword] = statePassword
  const setStep = stateStep[1]
  const user = useSelector('user')!
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = stateModal
  const [errorMessage, setErrorMessage] = useState('')

  const handleNextStep = useCallback(() => {
    if (password) {
      setLoading(true)
      requestData('auth_oauth_login', {
        data: {identifier: user.username, password},
        onRequestReceived: () => {
          setLoading(false)
        },
        onRequestSuccess: ({status, data: {detail}}) => {
          if (status === 200 || status === 202) {
            setStep(2)
            setPassword('')
            setErrorMessage('')
          } else if (status === 400 && detail) {
            setErrorMessage(
              translate('auth:validationLoginAttempt', {
                attempt: AUTH_LOGIN_MAX_ATTEMPT - Number(detail.attempt),
              }),
            )
          } else if (status === 423) {
            setModal(false)
            setPassword('')
            setErrorMessage('')
          }
        },
      })
    }
  }, [password, setPassword, setModal, setStep, user.username])

  const handleClickEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && password !== '') {
        handleNextStep()
      }
    },
    [handleNextStep, password],
  )

  const handleRenderError = useMemo(
    () =>
      errorMessage && (
        <StyledError color="danger_5" fontWeight="medium">
          {errorMessage}
        </StyledError>
      ),
    [errorMessage],
  )

  return (
    <StyledModal visible={modal}>
      <StyledParagraph fontSize="l" fontWeight="bold">
        {translate('giftshop:passwordConfirmation')}
      </StyledParagraph>
      <Input
        name="password"
        type="password"
        value={password}
        label={translate('global:password')}
        placeholder={translate('auth:inputPasswordConfirmPlaceholder')}
        rightIcon="visibility"
        onChangeText={setPassword}
        onKeyPress={handleClickEnter}
      />
      {handleRenderError}
      <StyledButtonsContainer>
        <Button
          label={translate('global:cancel')}
          color="primary_5"
          backgroundColor="white_2"
          onClick={onCancel}
          width={convertUnit(125)}
        />
        <Button
          isLoading={loading}
          label={translate('global:continue')}
          width={convertUnit(125)}
          onClick={handleNextStep}
          disabled={password === ''}
        />
      </StyledButtonsContainer>
    </StyledModal>
  )
}
