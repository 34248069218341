import React, {useCallback, useEffect, useMemo, useState} from 'react'
import styled from 'styled-components'
import {useExplorePagination} from 'context'
import {requestData} from 'services'
import {
  formatExploreFilterDate,
  showSnackbar,
  useDefaultState,
  useDidUpdate,
  useHistory,
} from 'utils'
import {GiftShopContentData} from 'types'
import {useTranslation} from 'i18n'
import {
  IMAGE_ASSET,
  SERVICE_CANCELLATION_GET_EXPLORE,
  SERVICE_CANCELLED_RESPONSE,
  WINDOW_MODE_TABLET_WIDTH,
} from 'consts'
import {useWindowMode} from 'windows'
import {
  ActivityIndicator,
  Button,
  ButtonOutline,
  Icon,
  Paragraph,
} from 'common/components'
import {useDispatch, useSelector} from 'lib/redux'
import convertUnit from 'lib/unit'
import {GiftShopTemplateMasonry} from '../../../template'
import {GiftShopRootEmpty} from '../../../root'
import {GiftShopExploreTabView} from '../ExploreTabView'
import {GiftShopExploreContentProps} from './GiftShopExploreContentProps'
import {GiftShopExplorePagination} from '../Pagination'

interface StyledButtonsProps {
  loading: boolean
}

const StyledMasonryContainer = styled.div`
  position: relative;
  height: 100%;
  flex: '1 1 auto';
`

const StyledButtonContainer = styled.div`
  margin-top: ${convertUnit(20)};
`

const StyledSelectBulkOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${convertUnit(12)} ${convertUnit(20)};
  gap: ${convertUnit(20)};
  @media (max-width: ${WINDOW_MODE_TABLET_WIDTH}px) {
    justify-content: space-between;
  }
`

const StyledSelectBulkOptionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${convertUnit(12)};
`

const StyledButtonOutline = styled(ButtonOutline)<StyledButtonsProps>`
  height: ${convertUnit(30)};
  cursor: ${({loading}) => (loading ? 'progress' : 'pointer')};
`

const StyledYesButton = styled(Button)<StyledButtonsProps>`
  height: ${convertUnit(30)};
  cursor: ${({loading}) => (loading ? 'progress' : 'pointer')};
`

const StyledTabAndPaginationContainer = styled.div`
  width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledActivityIndicatorContainer = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

const StyledActivityIndicator = styled.div`
  border-radius: 50%;
  background-color: ${({theme}) => theme.white_1};
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${convertUnit(20)};
`

const StyledPaginationFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: ${({theme}) => theme.white_1};
`

const StyledTabViewContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`

const StyledPaginationContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export default function GiftShopExploreContent({
  isFilter,
  stateSelect,
  stateSelectMode,
  stateLoading,
  stateData,
  disableBulkAction,
  onClearFilter,
  hideSelfieButton,
}: GiftShopExploreContentProps) {
  const {translate} = useTranslation()
  const history = useHistory()
  const [data, setData] = stateData
  const emptyImage = IMAGE_ASSET(
    'giftshop',
    isFilter
      ? 'roboyu-filter.png'
      : hideSelfieButton
      ? 'robopet-confused.png'
      : 'robopet-offer.png',
  )
  const filter = useSelector('exploreFilter')
  const [select, setSelect] = stateSelect
  const selectIds = useMemo(() => select.map((item) => item.content_id), [
    select,
  ])
  const [selectMode, setSelectMode] = stateSelectMode
  const [loading, setLoading] = stateLoading
  const {sensitivityLevel} = useSelector('roboyuSensitivityLevel')
  const handleTabViewVisible = useMemo(() => data.length !== 0 && !isFilter, [
    data.length,
    isFilter,
  ])
  const statePageContext = useExplorePagination()
  const statePage = useDefaultState(1, statePageContext)
  const [currPage, setCurrPage] = statePage
  const [loadPage, setLoadPage] = useState(currPage)
  const [isLoadData, setIsLoadData] = useState(false)
  const mode = useWindowMode()
  const {update} = useDispatch()
  const {exploreMonthPagination} = useSelector('exploreMonthPaginationState')
  const [refreshDate, setRefreshDate] = useState<Date>(new Date())

  const handleNavigateToItem = useCallback(
    (item: GiftShopContentData) => {
      history.push('giftshop_explore_detail', {
        data: [...data],
        selectedItemId: item.content_id,
        isFilter,
        isPaginated: !isFilter,
      })
    },
    [data, history, isFilter],
  )

  const handleClickItem = useCallback(
    (item: GiftShopContentData) =>
      loading
        ? undefined
        : selectMode
        ? setSelect((prev) =>
            prev.includes(item)
              ? prev.filter((val) => val.content_id !== item.content_id)
              : [...prev, item],
          )
        : handleNavigateToItem(item),
    [handleNavigateToItem, loading, selectMode, setSelect],
  )

  const handleCancelSelect = useCallback(() => {
    setSelect([])
    setSelectMode(false)
  }, [setSelect, setSelectMode])

  const handleBulkConfirm = useCallback(() => {
    setLoading(true)
    const isAllConfirmed = !select.filter((item) => item.type === 'is_this_you')
      .length
    if (isAllConfirmed) {
      setLoading(false)
      showSnackbar(translate('giftShop:isThisYouBulkConfirmSuccess'))
      handleCancelSelect()
    } else {
      requestData('giftshop_post_wishlist', {
        actionType: 'execute',
        useDefaultMessage: true,
        data: {content_ids: selectIds},
        onRequestReceived: () => setLoading(false),
        onRequestSuccess: ({status}) => {
          if (status === 200) {
            setData((prev) =>
              prev.map((item) => {
                if (selectIds.includes(item.content_id))
                  return {...item, is_wishlist: true, type: 'fotoyu'}
                return item
              }),
            )
            showSnackbar(translate('giftShop:isThisYouBulkConfirmSuccess'))
            handleCancelSelect()
          }
        },
      })
    }
  }, [handleCancelSelect, select, selectIds, setData, setLoading, translate])

  const handleBulkNope = useCallback(() => {
    setLoading(true)
    requestData('giftshop_delete_explore', {
      actionType: 'execute',
      useDefaultMessage: true,
      data: {content_ids: selectIds},
      onRequestReceived: () => setLoading(false),
      onRequestSuccess: ({status}) => {
        if (status === 200) {
          setData((prev) =>
            prev.filter((item) => !selectIds.includes(item.content_id)),
          )
          showSnackbar(translate('giftShop:isThisYouBulkConfirmSuccess'))
          handleCancelSelect()
        }
      },
    })
  }, [handleCancelSelect, selectIds, setData, setLoading, translate])

  const handleCheckMonths = useCallback(async () => {
    if (!isFilter) {
      const response = await requestData('giftshop_get_explore_month', {
        actionType: 'fetch',
        params: {
          sensitivity_level: sensitivityLevel,
        },
        onRequestSuccess: ({data: {result}}) => {
          if (result && currPage > result.length) {
            setCurrPage(result.length)
            setLoadPage(result.length)
          }
        },
      })
      if (typeof response !== 'string' && response.status === 200) {
        update('exploreMonthPaginationState', {
          exploreMonthPagination: response.data.result,
        })
        return response.data.result
      }
    }
    return []
  }, [currPage, isFilter, sensitivityLevel, setCurrPage, update])

  const handleLoadData = useCallback(
    async (page: number, limit: number) => {
      const monthPagination = await handleCheckMonths()
      const {
        startDate,
        endDate,
        latitude,
        longitude,
        tags,
        creators,
        filterSensitivity,
      } = filter
      const {startTimestamp, endTimestamp} = formatExploreFilterDate(
        startDate,
        endDate,
      )
      const tagIds = tags.map((item) => item.tag_id)
      const lat = parseFloat(latitude)
      const long = parseFloat(longitude)
      const creatorIds = creators
        ? creators.map((creator) => creator.creator_id)
        : []
      const response = await requestData('giftshop_post_explore', {
        actionType: 'execute',
        data: {
          tags: tagIds,
          page,
          limit,
          is_filter: isFilter,
          latitude: lat,
          longitude: long,
          type: 'all',
          from: startTimestamp,
          to: endTimestamp,
          creator_ids: creatorIds,
          sensitivity_level: isFilter ? filterSensitivity : sensitivityLevel,
          year:
            monthPagination && monthPagination.length && loadPage > 1
              ? monthPagination[loadPage - 1].year
              : undefined,
          month:
            monthPagination && monthPagination.length && loadPage > 1
              ? monthPagination[loadPage - 1].month
              : undefined,
        },
        cancelId: SERVICE_CANCELLATION_GET_EXPLORE,
      })

      if (
        typeof response === 'string' &&
        response === SERVICE_CANCELLED_RESPONSE
      ) {
        return undefined
      }

      if (typeof response !== 'string' && response.status === 200) {
        const {result} = response.data
        localStorage.setItem(
          'last_explore_time',
          String(new Date().getTime() / 1000),
        )
        setIsLoadData(false)
        if (result.length < limit && (page !== 1 || result.length !== 0))
          showSnackbar(translate('giftShop:fotoyuFinishedLoadingSnackbar'))
        return result.filter((item) => item.is_reported !== true)
      }
      setIsLoadData(false)
      return []
    },
    [
      filter,
      handleCheckMonths,
      isFilter,
      loadPage,
      sensitivityLevel,
      translate,
    ],
  )

  const handleRenderSelectBulkOption = useMemo(
    () => (
      <StyledSelectBulkOptionContainer>
        <Paragraph fontSize="m" fontWeight="bold">
          {translate('giftShop:isThisYou')}
        </Paragraph>
        <StyledSelectBulkOptionButtonContainer>
          <StyledButtonOutline
            label={translate('giftShop:fotoyuIsNotLabel')}
            color="gray_5"
            borderColor="gray_5"
            borderWidth={2}
            disabled={disableBulkAction}
            disabledBorderColor="gray_2"
            loading={loading}
            leftIcon={
              <Icon
                type="not-interested"
                size={16}
                color={disableBulkAction ? 'gray_2' : 'gray_5'}
                style={{marginRight: convertUnit(9)}}
              />
            }
            onClick={handleBulkNope}
          />
          <StyledYesButton
            label={translate('giftShop:fotoyuIsYesLabel')}
            disabled={disableBulkAction}
            loading={loading}
            leftIcon={
              <Icon
                type="check-custom"
                size={16}
                color={disableBulkAction ? 'gray_3' : 'white_1'}
                style={{marginRight: convertUnit(9)}}
              />
            }
            onClick={handleBulkConfirm}
          />
        </StyledSelectBulkOptionButtonContainer>
      </StyledSelectBulkOptionContainer>
    ),
    [disableBulkAction, handleBulkConfirm, handleBulkNope, loading, translate],
  )

  const handleTabView = useMemo(
    () =>
      handleTabViewVisible ? (
        selectMode ? (
          handleRenderSelectBulkOption
        ) : (
          <StyledTabViewContainer>
            <GiftShopExploreTabView selectedTab="all" />
          </StyledTabViewContainer>
        )
      ) : (
        <></>
      ),
    [handleRenderSelectBulkOption, handleTabViewVisible, selectMode],
  )

  const handleRenderPagination = useMemo(
    () =>
      !isFilter &&
      exploreMonthPagination && (
        <StyledPaginationContainer>
          <GiftShopExplorePagination
            pageCount={exploreMonthPagination.length}
            statePage={statePage}
          />
        </StyledPaginationContainer>
      ),
    [exploreMonthPagination, isFilter, statePage],
  )

  const handleRenderTabAndPagination = useMemo(
    () => (
      <StyledTabAndPaginationContainer>
        {handleTabView}
        {mode === 'website' ? handleRenderPagination : <></>}
      </StyledTabAndPaginationContainer>
    ),
    [handleRenderPagination, handleTabView, mode],
  )

  const handleClearFilterButton = useMemo(
    () =>
      isFilter && (
        <StyledButtonContainer>
          <Button
            onClick={onClearFilter}
            label={translate('giftShop:isThisYouFilterEmptyButton')}
          />
        </StyledButtonContainer>
      ),
    [isFilter, onClearFilter, translate],
  )

  const handleRenderAddSelfie = useMemo(
    () =>
      !isFilter &&
      !hideSelfieButton && (
        <StyledButtonContainer>
          <Button
            onClick={() => history.push('giftshop_robopet_retake', {})}
            label={translate('giftShop:retakeKYC')}
            leftIcon={<Icon type="face-scan" color="white_1" />}
          />
        </StyledButtonContainer>
      ),
    [hideSelfieButton, history, isFilter, translate],
  )

  const handleRenderEmpty = useMemo(
    () => (
      <GiftShopRootEmpty
        image={emptyImage}
        imageAlt={emptyImage}
        title={
          isFilter
            ? translate('giftShop:isThisYouFilterEmptyTitle')
            : hideSelfieButton
            ? translate('giftShop:similarWaitingApprovalSubtitle')
            : translate('giftShop:exploreEmptyTitle')
        }
        iconOnTitle={!isFilter && !hideSelfieButton}
        message={
          isFilter
            ? translate('giftShop:isThisYouFilterEmptyMessage')
            : hideSelfieButton
            ? translate('giftShop:similarWaitingApprovalDesc')
            : translate('giftShop:exploreEmptySubtitle1')
                .concat('\n')
                .concat(translate('giftShop:exploreEmptySubtitle2'))
        }
        bottomElement={
          isFilter ? handleClearFilterButton : handleRenderAddSelfie
        }
      />
    ),
    [
      emptyImage,
      handleClearFilterButton,
      handleRenderAddSelfie,
      hideSelfieButton,
      isFilter,
      translate,
    ],
  )

  const handleLoadActivityIndicator = useMemo(
    () =>
      isLoadData ? (
        <StyledActivityIndicatorContainer>
          <StyledActivityIndicator>
            <ActivityIndicator iconSize={30} iconColor="primary_5" />
          </StyledActivityIndicator>
        </StyledActivityIndicatorContainer>
      ) : (
        <></>
      ),
    [isLoadData],
  )

  useDidUpdate(() => {
    if (
      data.length === 0 &&
      exploreMonthPagination &&
      exploreMonthPagination.length > 1
    ) {
      setIsLoadData(true)
      if (currPage === exploreMonthPagination.length) {
        setCurrPage((prev) => prev - 1)
      }
      setRefreshDate(new Date())
    }
  }, [data.length])

  useEffect(() => {
    if (currPage === loadPage) return
    const timer = setTimeout(() => {
      setLoadPage(currPage)
      setRefreshDate(new Date())
      setIsLoadData(true)
    }, 300)

    return () => {
      clearTimeout(timer)
    }
  }, [currPage, loadPage])

  return (
    <>
      {handleRenderTabAndPagination}
      <StyledMasonryContainer>
        {handleLoadActivityIndicator}
        <GiftShopTemplateMasonry
          loadData={handleLoadData}
          stateData={stateData}
          showResBadge
          search={filter.lastUpdated}
          listEmptyElement={handleRenderEmpty}
          selected={select}
          toggleSelect={selectMode}
          onClickItem={handleClickItem}
          onSelectRule={(item) => item.type !== 'collection'}
          refresh={refreshDate}
        />
      </StyledMasonryContainer>
      {mode !== 'website' ? (
        <StyledPaginationFooter>
          {handleRenderPagination}
        </StyledPaginationFooter>
      ) : (
        <></>
      )}
    </>
  )
}
