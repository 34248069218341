import {TranslationKeyGiftShop} from '../../keys'
import {TranslationResource} from '../../TranslationType'

export const TRANSLATION_GIFT_SHOP_ID: TranslationResource<TranslationKeyGiftShop> = {
  translation: {
    'giftShop:about': 'Tentang',
    'giftShop:aboutContent': 'Tentang Konten',
    'giftShop:accessPermissionButton': 'Izinkan',
    'giftShop:accessPermissionDisclaimer':
      '(Hanya beberapa staff penting yang memiliki akses untuk keperluan pemeliharaan)',
    'giftShop:accessPermissionFirstDescription':
      'Untuk meningkatkan akurasi pencarian, FOTOYU memerlukan izin kamu untuk mengaktifkan layanan lokasi perangkat kamu.',
    'giftShop:accessPermissionFirstDescription_camera':
      'Untuk mengaktifkan akun dan mencari foto kamu, Fotoyu memerlukan persetujuanmu untuk mengaktifkan akses kameramu.',
    'giftShop:accessPermissionHeader': 'Izin Akses Lokasi',
    'giftShop:accessPermissionHeader_camera': 'Izin Akses Kamera',
    'giftShop:accessPermissionSecondDescription':
      'Data lokasi kamu dienkripsi oleh RoboYu, sehingga sebagian besar staf Fotoyu tidak dapat membaca data tersebut.',
    'giftShop:accessPermissionSecondDescription_camera':
      'Datamu dienkripsi oleh RoboYu, sehingga sebagian besar staf Fotoyu tidak dapat membaca data tersebut.',
    'giftShop:account': 'Akun',
    'giftShop:accountDetailHeader': 'Detil Akun',
    'giftShop:accountLabel': 'Rekening',
    'giftShop:accountName': 'Nama Akun',
    'giftShop:accountNumberAlreadyExist':
      'Nomor akun sudah teregistrasi sebelumnya',
    'giftshop:acountNumberCooldown': 'Mohon coba kembali dalam waktu {{timer}}',
    'giftShop:addAccountFailed': 'Ada masalah saat mengirim data kamu.',
    'giftShop:addAccountSuccess': 'Rekening bank baru kamu telah ditambahkan.',
    'giftShop:addBankAccount': 'Tambahkan rekening bank',
    'giftShop:addBankAccountHeader': 'Tambahkan Rekening Bank',
    'giftShop:addBankAccountCreatePasswordInfo':
      'Kamu harus membuat kata sandi untuk akun kamu sebelum menambahkan rekening bank',
    'giftShop:addBankInputAccountNameErrorMustAlphabet':
      'Nama akun harus alfabet',
    'giftShop:addBankInputAccountNameLabel': 'Nama Lengkap',
    'giftShop:addBankInputAccountNamePlaceholder':
      'Nama lengkap sesuai rekening bank',
    'giftShop:addBankInputAccountNumberErrorMinimalCharacter':
      'Jumlah nomor rekening minimal adalah 10 karakter',
    'giftShop:addBankInputAccountNumberErrorNumberOnly':
      'Nomor rekening hanya boleh berisi angka saja',
    'giftShop:addBankInputAccountNumberLabel': 'Nomor Rekening Bank',
    'giftShop:addBankInputAccountNumberPlaceholder':
      'Tambah nomor rekening bank',
    'giftShop:addBankInputBankBranchLabel': 'Nama Cabang Bank (Opsional)',
    'giftShop:addBankInputBankBranchPlaceholder': 'Tambah nama cabang bank',
    'giftShop:addBankInputBankNameLabel': 'Nama Bank',
    'giftShop:addBankInputBankNamePlaceholder': 'Tambah nama bank',
    'giftShop:addBioHeader': 'Tambahkan Bio',
    'giftShop:addCart': 'Tambahkan ke Keranjang',
    'giftShop:addCartSuccessMessage':
      'Konten berhasil ditambahkan ke keranjang.',
    'giftShop:addEmailErrorExist':
      'Maaf! Sepertinya alamat email ini telah terdaftar. Gunakan email lain dan coba lagi.',
    'giftShop:addEmailHeader': 'Tambah Email',
    'giftShop:addEmailPlaceholder': 'Someone@domain.com',
    'giftShop:addEmailVerifyDescription':
      'Diperlukan konfirmasi akun. Mohon cek email kamu untuk tautan konfirmasi.',
    'giftShop:addEmailVerifyDidNotGet': 'Tidak mendapat email?',
    'giftShop:addEmailVerifyExpiration':
      'Tautal verifikasi kamu akan kadaluarsa dalam 1 jam',
    'giftShop:addEmailVerifyResend': 'Kirim ulang email {{countdown}}',
    'giftShop:addEmailVerifySubtitle':
      'Kami telah mengirimkan email verifikasi.',
    'giftShop:addEmailVerifyTitle': 'Verifikasi Email kamu',
    'giftShop:addPhoneHeader': 'Tambah Nomor Telepon',
    'giftShop:addPhoneVerifyTitle': 'Verifikasi Nomor Teleponmu',
    'giftShop:addMainAccountFailed': 'Gagal mengatur akun sebagai akun utama',
    'giftShop:addMainAccountSuccess':
      'Akun berhasil diatur sebagain akun utama!',
    'giftShop:addPaymentAccount': 'Tambah Akun Pembayaran',
    'giftShop:addPhoto': 'Tambahkan Foto',
    'giftShop:addSelfieTooltip': 'Kamu dapat menambahkan selfie',
    'giftShop:addSocialLinkInputPlaceholder':
      'Tambahkan link akun {{socialType}} kamu',
    'giftShop:addSocialLinkInputPlaceholder_username':
      'Tambahkan username akun {{socialType}} kamu',
    'giftShop:addSocialLinkInputPlaceholder_number':
      'Tambahkan nomor akun {{socialType}} kamu',
    'giftShop:addSocialLinkInputPlaceholder_title': 'Tambahkan link',
    'giftShop:addSocialLinkInputPlaceholder_whatsapp': 'e.g. 62877xxxx',
    'giftshop:addSocialLinkTypePlaceholder': 'Pilih jenis media',
    'giftShop:addWishlistSuccessMessage':
      'Konten berhasil ditambahkan ke FotoYu.',
    'giftShop:additionalStorage': '+{{bonus}} MB',
    'giftShop:allContent': 'Semua konten kamu akan ditampilkan di sini.',
    'giftShop:allDiscounted': 'Semua Di-diskon',
    'giftShop:alltransaction':
      'Semua riwayat transaksi kamu akan ditampilkan di sini.',
    'giftShop:alreadyExists': '{{thing}} sudah pernah didaftarkan sebelumnya.',
    'giftShop:archive': 'Arsipkan',
    'giftShop:archiveEmptySubtitle':
      'Semua konten yang diarsip akan ditampilkan di sini.',
    'giftShop:archiveEmptyTitle':
      'Sepertinya kamu belum mengarsipkan konten apa pun.',
    'giftShop:archiveHeader': 'Konten Terarsip',
    'giftShop:archiveHeaderSubtitle':
      'Kamu bisa membatalkan pengarsipan kapan pun',
    'giftShop:archiveSuccessMessage':
      'Konten diarsip. Kamu dapat mengeluarkan kembali dari menu ⋮',
    'giftShop:archiveUnarchive': 'Batal Arsip',
    'giftShop:archiveUnarchiveSuccessMessage':
      'Pengarsipan konten berhasil dibuka.',
    'giftShop:availableBalanceLabel': 'Saldo Tersedia',
    'giftShop:backTo': 'Kembali ke {{where}}',
    'giftShop:balance': 'Saldo Saya',
    'giftShop:bankAccount': 'Rekening Bank',
    'giftShop:bankAccountDescription':
      'Tampilkan semua rekening bank yang terdaftar atau tambah rekening baru',
    'giftShop:bankAccountDetail': 'Rekening {{bankName}} ({{name}}) ',
    'giftShop:bankAccountListHeader': 'Daftar Rekening Bank',
    'giftShop:bankAccountMain': 'UTAMA',
    'giftShop:bankAccountMaxMessage':
      'Kamu hanya bisa menambahkan {{max}} akun bank.',
    'giftShop:bankAccountNotFound': 'Tidak Ada Akun Bank',
    'giftShop:bankAccountVerified': 'Terverifikasi',
    'giftShop:bankAccountNotVerified': 'Belum terverifikasi',
    'giftShop:batchResend': 'Kirim Ulang Sekaligus',
    'giftShop:beforeDeleteAccount':
      'Sebelum menghapus akunmu, kamu perlu memastikan:',
    'giftShop:beforeDeleteAccountListOne':
      'Gunakan/Tarik saldo di Balance kamu. Uang tersisa di Balance akan hangus ketika akunmu dihapus. Selain itu, balance kamu tidak boleh negatif.',
    'giftShop:beforeDeleteAccountListTwo':
      'Tidak sedang terikat dengan Host apapun, baik sebagai Host Kreator Internal, Host leader, Host admin maupun kerjasama Host lainnya.',
    'giftShop:beforeDeleteAccountListThree':
      'Tidak memiliki hutang Resend HiRes kepada Yuser lain.',
    'giftShop:beforeDeleteAccountListFour':
      'Sudah mengunduh semua koleksi kamu.',
    'giftShop:bio': 'Bio',
    'giftShop:bioMaxMessage': 'Maksimum {{count}} karakter',
    'giftShop:blockedAccounts': 'Akun yang Diblokir',
    'giftShop:emptyBlockedAccountsTitle': 'Anda belum memblokir siapapun',
    'giftShop:emptyBlockedAccountsDesc':
      'Ketika anda memblokir seseorang, nama pengguna mereka akan muncul di sini.',
    'giftShop:branchName': 'Nama Cabang Bank',
    'giftShop:buy': 'Beli',
    'giftShop:buyNow': 'Beli Sekarang',
    'giftShop:cancelPayment': 'Batal Pembayaran',
    'giftShop:cartEmptyInformationDescription':
      'Semua konten yang kamu tambahkan akan ditampilkan di sini.',
    'giftShop:cartEmptyInformationTitle':
      'Sepertinya kamu belum menambahkan konten apa pun...',
    'giftShop:cartHeader': 'Keranjang Saya',
    'giftShop:cartMaximumErrorMessage': 'Keranjang sudah mencapai batasnya',
    'giftShop:cartSummaryTitle': 'Ringkasan Pesanan',
    'giftShop:cartSummaryTotalPriceTitle': 'Harga Total ({{count}} barang)',
    'giftShop:cartSummaryTotalPriceTitle_plural':
      'Harga Total ({{count}} barang)',
    'giftShop:changeBioHeader': 'Ubah Bio',
    'giftShop:changeBioPlaceholder': 'Gambarkan diri kamu',
    'giftShop:changeDateOfBirthHeader': 'Ubah Tanggal Lahir',
    'giftShop:changeDateOfBirthMessage':
      'Kamu hanya dapat mengubah data ini sekali. Harap berhati-hati dan periksa kembali sebelum menyimpan perubahan.',
    'giftShop:changeEmailHeader': 'Ubah Email',
    'giftShop:changeFullnameHeader': 'Ubah Nama Lengkap',
    'giftShop:changeLanguage': 'Ganti Bahasa',
    'giftShop:changeNickname': 'Ubah Nickname',
    'giftShop:changeNicknameSuccess': 'Nickname berhasil diubah.',
    'giftShop:changeNicknameWarning':
      'Nickname-mu hanya dapat diubah setiap {{limit}} hari sekali.',
    'giftShop:changePasswordLimitDesc':
      'Maaf, kami tidak dapat memproses permintaan kamu karena terlalu banyak upaya yang gagal. Silakan coba lagi dalam 1 jam.',
    'giftShop:changePasswordLimitTitle': 'Terlalu banyak usaha yang gagal',
    'giftShop:changePasswordSuccessSnackbar':
      'Mohon periksa kotak masuk kamu dan ikuti instruksinya.',
    'giftShop:changePasswordWarning':
      'Mengubah kata sandi kamu akan membuat kamu keluar dari semua sesi kecuali sesi ini, untuk menjaga keamanan akun kamu.',
    'giftShop:changePhoneHeader': 'Ubah Nomor Telepon',
    'giftShop:changePhotoButton': 'Ubah Foto',
    'giftShop:changePicture': 'Ganti Foto',
    'giftShop:changeUsername': 'Ubah Username',
    'giftShop:changeUsernameSuccess': 'Username berhasil diubah.',
    'giftShop:changeUsernameWarning':
      'Username-mu hanya dapat diubah setiap {{limit}} hari sekali.',
    'giftShop:checkoutDurationEnds': 'Durasi Pembayaran Berakhir',
    'giftShop:checkoutTimeLeft': 'Sisa Waktu:',
    'giftShop:checkoutHeader': 'Checkout',
    'giftShop:checkoutTransactionDate': 'Tanggal Transaksi',
    'giftShop:checkoutRefundMessage':
      'Kreator harus mengirimkan Hi-Res dalam 48 jam, atau kamu akan menerima refund (dipotong biaya bank).',
    'giftShop:choosePicture': 'Pilih Gambar',
    'giftShop:chooseYuser': 'Pilih Yuser',
    'giftShop:chooseYuserDesc':
      'Daftar yuser di bawah ini adalah mereka yang telah membeli konten ini.',
    'giftShop:checkboxDeleteAccountPlaceholder':
      'Saya sudah membaca dan menyetujui ketentuan PENGHAPUSAN AKUN SAYA',
    'giftShop:codeExpired': 'Kode telah kedaluwarsa. Silakan coba lagi.',
    'giftShop:codeExpiresIn': 'Kode kadaluarsa pada: ',
    'giftShop:collection': 'Koleksi',
    'giftShop:collectionEmptyDesc':
      'Konten yang kamu beli akan ditampilkan di sini.',
    'giftShop:collectionEmptyTitle':
      'Sepertinya kamu belum membeli konten apa pun...',
    'giftShop:collectionHeader': 'Koleksi',
    'giftShop:collectionHiResBadge': 'Hi-res',
    'giftShop:collectionPreviewBadge': 'Belum Hi-Res',
    'giftShop:collectorPay': 'Harga Jual ',
    'giftShop:commingSoon': 'Kami sedang mengerjakannya',
    'giftShop:commingSoonDesc':
      'Kami ingin memberi kamu fitur hebat yang juga berfungsi dengan baik.',
    'giftShop:compressAndResend': 'Kompres & Kirim Ulang',
    'giftShop:compressActivated': ' Kompres automatis telah aktif',
    'giftShop:compressDeactivated': 'Kompres automatis telah dinonaktifkan',
    'giftShop:compressHeader': 'Selalu Kompres gambar saat Unggah Konten Jual',
    'giftShop:compressInfoLabel': 'Dengan mengaktifkan alat Kompresi gambar:',
    'giftShop:compressInfoMessage1':
      'Setiap konten yang kamu unggah akan dikompresi, menggunakan lebih sedikit ruang penyimpanan, dan ditandai “Belum Hi-Res”.',
    'giftShop:compressInfoMessage2':
      'Setiap kali konten "Belum Hi-Res" terjual, kamu harus mengirim ulang konten Hi-Res dalam 48 jam.',
    'giftShop:compressInfoMessage3':
      'Dengan cara ini kamu hanya perlu mengedit konten yang terjual, untuk mengirim ulang versi Hi-Res. Efisien.',
    'giftShop:compressInfoMessage4':
      'Melewati batas waktu 48 jam akan memicu proses refund. 100% dari Harga Dasar konten kamu akan dikembalikan kepada pembeli.',
    'giftShop:compressModalTitle': 'Mengompres...',
    'giftShop:compressModalDescription':
      '{{success}} dari {{total}} Konten Terkompres.',
    'giftShop:compressStatus': 'Kompres: ',
    'giftShop:contentType': 'Tipe Konten',
    'giftShop:confirmNewPassword': 'Konfirmasi Kata Sandi Baru',
    'giftShop:confirmNewPasswordPlaceholder':
      'Konfirmasi kata sandi baru kamu lagi',
    'giftShop:contentCount': '{{count}}/{{max}} Konten',
    'giftShop:contentCount_plural': '{{count}}/{{max}} Konten',
    'giftShop:contentDeleted': 'Konten terhapus.',
    'giftShop:contentDetail': 'Rincian Konten',
    'giftShop:contentDetailDiscountInformation':
      'Beli {{content_count}} dari {{creator_username}}, dapatkan diskon {{discount}}%',
    'giftShop:contentDetailEditDeleteDescription':
      'Apakah kamu yakin akan menghapus konten ini? Tindakan ini tidak bisa dibatalkan.',
    'giftShop:contentDetailEditHeader': 'Edit Konten',
    'giftShop:contentDetailEditModalMessage':
      'Apakah kamu yakin ingin membatalkan? Perubahan tidak akan disimpan',
    'giftShop:contentDetailFilterDescription':
      'Konten ini mungkin melanggar Aturan Komunitas FotoYu.',
    'giftShop:contentDetailFilterTitle': 'Gagal Mengunggah',
    'giftShop:contentDetailReportContent': 'Laporkan Konten',
    'giftShop:contentModalLoadingHeader': 'Memuat Konten...',
    'giftShop:contentModalLoadingDescription':
      '{{success}} dari {{total}} Konten Dimuat.',
    'giftShop:contentModalLoadingProcessingDescription':
      'Membuat {{success}} dari {{total}} Pratinjau Konten.',
    'giftShop:contentModalLoadingUploadingDescription':
      'Mengunggah {{success}} dari {{total}} Pratinjau Konten.',
    'giftShop:createPassword': 'Buat Kata Sandi',
    'giftShop:creation': 'Kreasi',
    'giftShop:creationBadge': 'Tersedia',
    'giftShop:creationBadge_resend': 'Kirim ulang Hi-res',
    'giftShop:creationBadge_resendpology': 'Tetap Kirim Ulang',
    'giftShop:creationBadge_sold': 'Terjual',
    'giftShop:creationBadge_unwishlist': 'Dihapus oleh yuser',
    'giftShop:creationChip': 'Semua',
    'giftShop:creationChip_all': 'Semua',
    'giftShop:creationChip_available': 'Tersedia',
    'giftShop:creationChip_resend': 'Kirim Ulang',
    'giftShop:creationChip_sold': 'Terjual',
    'giftShop:creationFilterModalTitle': 'Pilihan Filter',
    'giftShop:creationFilteredEmpty': 'Konten Tidak Ditemukan',
    'giftShop:creationFilteredEmptyDesc': 'Harap perbarui filter untuk menemukan konten yang relevan.',
    'giftShop:creationFilterEmpty':
      'Unggah kreasi kamu, konten terkait akan muncul di sini',
    'giftShop:creationFilterEmptyAll':
      'Unggah kreasi fotomu ke server FotoYu agar bisa otomatis ditemukan oleh klien atau temanmu.',
    'giftShop:creationFilterEmptyAllButton': 'Unggah Jual',
    'giftShop:creationGroupDeleteDescription':
      'Kamu yakin ingin menghapus folder & konten di dalamnya?',
    'giftShop:creationGroupDeleteSubDescription':
      'Konten Terjual atau sedang Checkout tidak dapat dihapus.',
    'giftShop:creationGroupDeleteHeader': 'Hapus Folder',
    'giftShop:creationGroupDeleteItemsDescription':
      'Kamu yakin ingin menghapus konten ini? Tindakan ini tidak bisa dibatalkan',
    'giftShop:creationGroupDeleteItemsHeader': 'Hapus Konten Item',
    'giftShop:creationGroupDeleteMessageFailed': 'Gagal menghapus folder.',
    'giftShop:creationGroupDeleteMessageSuccess': 'Folder berhasil dihapus.',
    'giftShop:creationGroupEditMessageFailed': 'Gagal memperbarui konten.',
    'giftShop:creationGroupEditMessageSuccess': 'Konten berhasil diperbarui.',
    'giftShop:creationSingleEditMessageSuccess': 'Konten berhasil diubah.',
    'giftShop:creationSortByTitle': 'Urutkan Kirim Ulang Konten berdasarkan',
    'giftShop:creationSortBy': 'Nama File (Default)',
    'giftShop:creationSortBy_purchasedAt': 'Jatuh Tempo Terawal',
    'giftShop:creationSortBy_title': 'Nama File (Default)',
    'giftShop:creator': 'Kreator',
    'giftShop:creatorPriceLimitErrorMessage': 'Maks. IDR 9.000.',
    'giftShop:creatorPriceLimitErrorMessageContinue':
      'Tingkatkan akunmu untuk harga lebih tinggi.',
    'giftShop:creatorPriceLimitModalDescription':
      'Batas harga kreator adalah Rp 9.000/konten. Silahkan upgrade untuk bisa mengatur harga hingga 1 juta rupiah.',
    'giftShop:creatorPriceLimitUpgradeModalDescription':
      'Konten ini tidak akan tersimpan',
    'giftShop:creatorPriceLimitModalTitle': 'Batas Harga Kreator',
    'giftShop:creatorPriceLimitUpgradeModalTitle':
      'Tingkatkan ke Kreator Terverifikasi',
    'giftShop:currentPassword': 'Kata sandi Sekarang',
    'giftShop:deleteAccount': 'Hapus Akun',
    'giftshop:deleteAccountFailed': 'Ada masalah saat menghapus akun kamu.',
    'giftshop:deleteAccountPrompt':
      'Apakah kamu yakin ingin menghapus akun kamu?',
    'giftshop:deleteAccountSuccess': 'Akun kamu sudah dihapus',
    'giftshop:deleteAccountWarning':
      'Selewatnya 7 hari, data kamu tidak dapat dipulihkan kembali.',
    'giftShop:deleteMyAccount': 'Hapus Akun Saya',
    'giftShop:deleteAccountDetail': 'Detail Hapus Akun',
    'giftShop:currencyPrice': 'IDR 0,00',
    'giftShop:deleteBankAccount': 'Hapus Rekening Bank',
    'giftShop:deleteContentConfirmationMessage':
      'Apakah kamu yakin ingin menghapus konten? Aksi ini tidak dapat dibatalkan.',
    'giftShop:deleteContentConfirmationTitle': 'Hapus Konten',
    'giftShop:deleteItem': 'Buang {{thing}}',
    'giftShop:deleteParentSeriesMessage':
      'Kamu perlu mengganti gambar utama dengan yang baru sebelum menghapus.',
    'giftShop:deleteResendpologyNotification': 'Notifikasi dihapus',
    'giftShop:deleteResendpologyConfirmationModalDescription':
      'Kamu yakin ingin menghapus notifikasi Tetap Kirim Ulang ini? Tindakan ini tidak bisa dibatalkan.',
    'giftShop:deleteResendpologyConfirmationModalTitle':
      'Hapus Item Notifikasi',
    'giftShop:didNotReceiveTheCode': 'Tidak menerima kode?',
    'giftShop:discount': 'Diskon',
    'giftShop:discountPercent': 'Diskon {{count}}%',
    'giftShop:discountText': 'Beli {{count}} dari {{username}}',
    'giftShop:editContentBulkDescription':
      'Perubahan kamu akan diterapkan pada semua konten terpilih.',
    'giftShop:editContentBulkHeader': 'Edit Konten',
    'giftShop:editProfile': 'Sunting Profil',
    'giftShop:editProfileDescription':
      'Edit nama lengkap, nama panggilan, email, dll.',
    'giftShop:editProfileDisclaimer':
      'Kami tidak menampilkan data di atas selain username, nickname, dan foto pada profil publik kamu',
    'giftShop:editProfileInputPasswordLimit': 'Melebihi Batas Input Kata Sandi',
    'giftShop:editProfileInvalidFormatData': 'Format Invalid',
    'giftShop:editProfileMaxFileSize': 'Ukuran file maksimum adalah 10 MB',
    'giftShop:editProfileMaxResolution': 'Maksimum Piksel adalah 16 MP',
    'giftShop:editProfileBackgroundSize':
      'Ukuran minimum {{height}} x {{width}} pixel',
    'giftShop:editProfileNotConfirmed': '(Belum dikonfirmasi)',
    'giftShop:editProfileUnverifiedCaption':
      'Kamu menambahkan {{item}} baru ini sebelumnya, namun belum diverifikasi.',
    'giftShop:editProfileVerifyPasswordCaption':
      'Kami menemukan bahwa kamu belum membuat kata sandi.',
    'giftShop:editProfileVerifyPasswordDescription':
      'Kamu harus membuat kata sandi terlebih dahulu untuk menambahkan email.',
    'giftShop:editProfileVerifyPasswordDescription_nickname':
      'Kamu harus membuat kata sandi terlebih dahulu untuk mengubah nickname.',
    'giftShop:editProfileVerifyPasswordDescription_phone':
      'Kamu harus membuat kata sandi terlebih dahulu untuk menambahkan nomor ponsel.',
    'giftShop:editProfileVerifyPasswordDescription_username':
      'Kamu harus membuat kata sandi terlebih dahulu untuk mengubah username.',
    'giftShop:emptyDefaultMessage': 'Yuk berinteraksi dengan teman-temanmu',
    'giftshop:emptyLocation': 'Mohon mengisi data lokasi',
    'giftShop:emailTo': 'Email ke',
    'giftShop:enterTheOTPSentAddBank':
      'Masukan OTP yang telah dikirim ke {{number}}',
    'giftShop:enterTheOTPSentSetting': 'Masukan OTP yang dikirim ke ',
    'giftShop:eWalletQRInstruction':
      'Silahkan scan QR code di bawah ini menggunakan fitur scan {{method}} di aplikasi {{app}}.',
    'giftShop:eWalletQRInstruction_second':
      'Ketika proses selesai, pembayaran akan diproses secara otomatis.',
    'giftShop:explore': 'Kamu?',
    'giftShop:exploreChangeAccuracyTooltip':
      'Kamu dapat mengubah akurasi RoboYu',
    'giftShop:exploreEmptySubtitle1':
      'Kamu juga dapat menghubungi/menunggu fotografer untuk mengunggah fotomu.',
    'giftShop:exploreEmptySubtitle2': 'Jangan buat akun kedua.',
    'giftShop:exploreEmptyTitle':
      'Jika foto belum ditemukan, coba tambahkan selfie di menu RoboYu ',
    'giftShop:exploreFilterClear': 'Hapus Filter',
    'giftShop:exploreFilterCreatorLabel': 'Kreator (Opsional)',
    'giftShop:exploreFilterDateTimeLabel': 'Tanggal dan Waktu (Opsional)',
    'giftShop:exploreFilterLocationLabel': 'Lokasi (Opsional)',
    'giftShop:exploreFilterPictureLabel': 'Cocokkan dengan gambar',
    'giftShop:exploreFilterTagsLabel': 'FotoTree (Opsional)',
    'giftShop:exploreFilterCreatorPlaceholder': 'Ketik username Kreator',
    'giftShop:exploreFilterDatePlaceholder': 'Pilih tanggal',
    'giftShop:exploreFilterTimePlaceholder': 'Pilih waktu',
    'giftShop:exploreFilterLocationPlaceholder': 'Pilih lokasi',
    'giftShop:exploreFilterPicturePlaceholder': 'Tambahkan gambar',
    'giftShop:exploreFilterTagsPlaceholder': 'Ketik nama FotoTree',
    'giftShop:exploreFloatingButtonTitle': 'RoboYu menemukan konten baru',
    'giftShop:exploreFloatingButtonSubtitle': 'Tekan untuk melihat',
    'giftShop:exploreHeader': 'Ini kamu?',
    'giftShop:exploreSearchTemporary': 'Fitur ini akan segera hadir!',
    'giftShop:exploreSearchTitle': 'Pencarian',
    'giftShop:externalLink': 'Tautan Eksternal',
    'giftShop:externalLinkDesc':
      'Kamu yakin ingin meninggalkan aplikasi dan membuka tautan ini?',
    'giftShop:facebook': 'Facebook',
    'giftShop:failedTransaction': 'Transaksi Gagal',
    'giftShop:favorite': 'Favorit',
    'giftShop:favoriteEmptySubtitle':
      'Semua konten yang ditambahkan akan ditampilkan di sini.',
    'giftShop:favoriteEmptyTitle':
      'Sepertinya kamu belum menambahkan konten apa pun ke Favorit...',
    'giftShop:favoriteErrorAddFavorite': 'Gagal ketika menambahkan ke favorit',
    'giftShop:favoriteErrorAddFavoriteAlreadyExist':
      'Konten sudah ada di dalam favorit',
    'giftShop:favoriteErrorRemoveFavorite':
      'Gagal ketika menghapus dari favorit',
    'giftShop:favoriteSuccessAdd': 'Konten ditambahkan ke Tab Favorit.',
    'giftShop:favoriteSuccessRemove': 'Konten dihapus dari Tab Favorit.',
    'giftShop:fillTheDateError': 'Isi tanggal',
    'giftShop:fillTheTimeError': 'Isi Waktu',
    'giftShop:firstPromo': 'Promo Pertama',
    'giftShop:fototreeCannotAddMoreThanOneSnackbar':
      'Maaf! Kamu hanya bisa menambahkan satu FotoTree.',
    'giftShop:followerTitle': 'Pengikut',
    'giftShop:followingTitle': 'Mengikuti',
    'giftShop:forGiftshopPurchaseOnly': '(hanya untuk pembelian Giftshop)',
    'giftShop:forgotPasswordDidNotGetEmail': 'Tidak mendapatkan email? ',
    'giftShop:forgotPasswordEdit': 'Saya Lupa Kata Sandi Saat Ini',
    'giftShop:forgotPasswordPhoneHeader':
      'Sebuah tautan telah dikirim ke nomor telepon kamu untuk melanjutkan proses.',
    'giftShop:forgotPasswordSuccessBottomText':
      'Sekarang kamu bisa login dengan password baru. Nikmati!',
    'giftShop:forgotPasswordSuccessDescription':
      'Kata sandi kamu berhasil diganti.',
    'giftShop:fotoyuEmptyDesc': 'Semua konten akan ditampilkan di sini.',
    'giftShop:fotoyuEmptyTitle':
      'Sepertinya kamu belum menambahkan konten apapun ke dalam FotoYu...',
    'giftShop:fotoyuFinishedLoadingSnackbar':
      'Hasil temuan saat ini. RoboYu akan terus mencari konten yang baru diunggah oleh Fotografer.',
    'giftShop:fotoyuIsNotLabel': 'Bukan',
    'giftShop:fotoyuIsYesLabel': 'Iya',
    'giftShop:foundByLabel': 'Ditemukan oleh:',
    'giftShop:freeContentInfoBox':
      'Kamu punya voucher gratis, untuk pembelian foto pertama di ',
    'giftShop:finalPrice': 'Harga Akhir',
    'giftShop:firstBuyer': 'Pembeli Pertama',
    'giftShop:firstBuyReferralVerifiedModalDescriptionPartOne':
      'Sebagai seorang kreator ',
    'giftShop:firstBuyReferralVerifiedModalDescriptionPartTwo':
      ', kamu mendapat penyimpanan gratis sebesar 5 GB. Dapatkan tambahan 50 MB penyimpanan / yuser baru yang membeli pertama kali di FotoYu dari kamu. Tingkatkan sampai 10 GB maksimum penyimpanan.',
    'giftShop:firstBuyReferralModalDescription':
      'Sebagai seorang kreator, kamu mendapat penyimpanan gratis sebesar 500 MB. Dapatkan tambahan 50 MB penyimpanan / yuser baru yang membeli pertama kali di FotoYu dari kamu. Tingkatkan sampai 1 GB maksimum penyimpanan.',
    'giftShop:firstBuyReferralModalFooterPartOne':
      'Kamu bisa meningkatkan akun kamu menjadi Kreator ',
    'giftShop:firstBuyReferralModalFooterPartTwo':
      ' untuk penyimpanan yang lebih besar.',
    'giftShop:firstBuyReferralModalTitle': 'Kreator',
    'giftShop:helpAndSupport': 'Bantuan & Dukungan',
    'giftshop:hiddenContent': 'Konten Tersembunyi',
    'giftShop:hiresConfirmationCompleteTime':
      'Transaksi akan selesai secara otomatis setelah:',
    'giftShop:hiresConfirmationEmptyInfo':
      'Semua konten yang memerlukan konfirmasi akan ditampilkan di sini.',
    'giftShop:hiresConfirmationEmptyTitle':
      'Sepertinya kamu telah mengonfirmasi semua konten!',
    'giftShop:hiresConfirmationHeader': 'Konfirmasi Hi-Res',
    'giftShop:hiresConfirmationSuccessSnackbar':
      'Konten berhasil dikonfirmasi.',
    'giftShop:hiresConfirmationWaitingSubtitle':
      'Harap konfirmasi konten hi-res berikut.',
    'giftShop:hiresConfirmationWaitingTitle': 'Menunggu Konfirmasi',
    'giftShop:history': 'Riwayat',
    'giftShop:historyDescription': 'Meninjau ulang sejarah transaksi',
    'giftShop:host': 'Host',
    'giftShop:hostNotification': 'Notifikasi Host',
    'giftShop:howToPay': 'Cara Membayar',
    'giftShop:ifDeleteAccount': 'Jika kamu menghapus akunmu: ',
    'giftShop:ifDeleteAccountListOne':
      'FotoYu akan secara permanen menghapus identifikasi biometrik wajah, data lokasi, konten, postingan, profil, storage dan semua data lain dari akunmu.',
    'giftShop:ifDeleteAccountListTwo':
      'Untuk aktivitas lampau yang terhubung dengan yuser lain, seperti chat, komentar, foto yang terjual, likes, follow dan jejak digital aktivitas lainnya, tidak semuanya dapat dihapuskan karena terintegrasi satu sama lain.',
    'giftShop:ifDeleteAccountListThreePartOne':
      'Akun kamu akan dinonaktifkan dan disembunyikan dari publik selama ',
    'giftShop:ifDeleteAccountListThreePartTwo':
      '. Kamu dapat memulihkan akunmu selama masa penonaktifan. Selewatnya ',
    'giftShop:ifDeleteAccountListThreePartThree':
      ', data kamu akan dihapus secara permanen.',
    'giftShop:ifDeleteAccountListFour':
      'Kamu tidak akan dapat masuk dan menggunakan layanan FotoYu dengan akun ini.',
    'giftShop:ifDeleteAccountListFive':
      'Kamu tidak dapat mendaftar ulang dengan nama nickname yang sama lagi.',
    'giftShop:incorrectOTP': 'OTP salah.',
    'giftShop:incorrectOTPPleaseEnterCorrect': 'Harap masukkan OTP yang benar.',
    'giftShop:instagram': 'Instagram',
    'giftShop:insufficientBalance':
      'Maaf, saldo kamu tidak cukup untuk melakukan penarikan',
    'giftShop:invoiceID': 'Invoice ID',
    'giftShop:isThisYou': 'Ini Kamu?',
    'giftShop:isThisYouBulkConfirmSuccess': 'Konten berhasil dikonfirmasi.',
    'giftShop:isThisYouEmptyDesc':
      'Kamu bisa membantu dengan memiliki selfie yang jelas di menu RoboYu ',
    'giftShop:isThisYouEmptyDesc_Alt':
      'Kamu juga dapat menghubungi fotografer untuk mengunggah fotomu.',
    'giftShop:isThisYouFilterEmptyButton': 'Hapus Filter',
    'giftShop:isThisYouEmptyTitle': 'RoboYu belum menemukan konten baru.',
    'giftShop:isThisYouFilterEmptyMessage':
      'RoboYu belum menemukan apa yang kamu cari. Jangan khawatir, RoboYu akan terus mencari.',
    'giftShop:isThisYouFilterEmptyTitle': 'Filter telah diaktifkan! Tapi...',
    'giftShop:invalidFormatData': 'Format file tidak sesuai',
    'giftShop:itemPurchased': 'Barang Dibeli',
    'giftShop:kycAgreement':
      'Saya memberikan persetujuan saya kepada PT. Super Giga Generasi untuk mengumpulkan atau mengungkapkan Pengenal Biometrik Wajah dan Data Lokasi Perangkat saya.',
    'giftShop:kycCancelMessage':
      'Kamu yakin ingin batal? Perubahan yang kamu buat tidak akan disimpan.',
    'giftShop:kycExampleBad': 'Contoh yang Salah:',
    'giftShop:kycExampleGood': 'Contoh yang Benar:',
    'giftShop:kycFollowInstruction': 'Ikuti instruksi saat kamu memulai',
    'giftShop:kycFollowInstructionFull':
      'Kualitas Selfie Mempengaruhi Akurasi Pencarian Foto',
    'giftShop:kycRecord': 'Lihat depan',
    'giftShop:kycRecord_left': 'Lihat kiri',
    'giftShop:kycRecord_right': 'Lihat kanan',
    'giftShop:kycRecord_side': 'Lihat samping',
    'giftShop:kycSatisfiedMessage':
      'Harap tunggu sementara kami memeriksa informasimu. Kami akan memberitahu kamu setelah proses selesai.',
    'giftShop:kycSelfie': 'Ambil selfie',
    'giftShop:kycSelfieRetake': 'Ambil ulang',
    'giftShop:kycSelfieReviewMessage':
      'Perhatian! Pencahayaan yang buruk dan wajah buram akan mempersulit pencarian foto kamu.',
    'giftShop:kycSelfieReviewTitle': 'Apakah kamu puas dengan hasilnya?',
    'giftShop:kycSelfieWarning':
      'Harap lepaskan kacamata kamu dan pastikan wajahmu jelas dan tidak tertutup oleh aksesori dan rambut',
    'giftShop:kycSelfieWarning_1': 'Harap jangan menggunakan aksesoris apapun',
    'giftShop:kycSelfieWarning_2':
      'Pastikan wajah kamu jelas dan tidak tertutup rambut',
    'giftShop:kycSelfieWarning_3':
      'Atur pencahayaan agar wajah kamu terlihat jelas di kamera',
    'giftShop:kycTapToStart': 'Tekan untuk mulai merekam',
    'giftShop:kycUploading': 'Mengunggah {{current}} dari {{total}}',
    'giftShop:kycUploading_preparing': 'Menyiapkan',
    'giftShop:kycWarningUnsupported':
      '(Saat ini, pendaftaran web kami lebih optimal dengan Safari.)',
    'giftShop:languageEmpty': 'Maaf! Bahasa Tidak Ditemukan.',
    'giftShop:languageEmptyDescription':
      'Silakan coba lagi menggunakan kata kunci lain.',
    'giftShop:makeItFree': 'Buat Gratis',
    'giftShop:makeItFreeDescription':
      'Apakah Anda yakin ingin membuat konten ini gratis?',
    'giftShop:makeItFreeSnackBarSuccess':
      'Selesai! Periksa riwayat transaksi Anda untuk detail lebih lanjut.',
    'giftShop:managePasswordHeader': 'Kelola Kata Sandi',
    'giftShop:manageAccount': 'Kelola Akun',
    'giftShop:manageAccountResendDescription1':
      'Semua unggahan konten untuk dijual akan ditandai sebagai versi "Belum Hi-Res".',
    'giftShop:manageAccountResendDescription2':
      'Sehingga, untuk preview Yuser, kamu bisa meng-unggah konten tanpa edit dan meng-kompresnya untuk menghemat storage akun kamu.',
    'giftShop:manageAccountResendDescription3':
      'Setiap kali sebuah konten terjual, kamu perlu mengirim versi Hi-Res dalam 48 jam.',
    'giftShop:manageAccountResendDescription4':
      'Kamu bisa menggunakan waktu tersebut untuk meng-edit dan Resend file Hi-Res kepada pembeli.',
    'giftShop:manageAccountResendDescription5':
      'Melewati batas waktu akan memicu 100% dari Harga Dasar konten kamu dikembalikan kepada pembeli.',
    'giftShop:manageAccountResendSubtitle':
      'Dengan mengaktifkan Selalu Kirim Ulang:',
    'giftShop:manageAccountResendTitle':
      'Selalu Kirim Ulang Hi-Res pada Konten Terjual',
    'giftShop:maps': 'Peta',
    'giftShop:messageBackToEditProfile': 'Kembali ke Ubah Profil',
    'giftShop:modalDeleteBankAccount':
      'Apakah kamu yakin untuk membuang akun ini?',
    'giftShop:modalLoadingDescription':
      'Mohon tunggu sebentar, dan jangan tutup halaman ini.',
    'giftShop:modalRemoveConfirmationMessage':
      'Aksi ini tidak dapat dibatalkan',
    'giftShop:modalRemoveFromMain':
      'Apakah kamu yakin untuk unset akun ini dari akun utama kamu?',
    'giftShop:modalRemovePicture':
      'Apakah kamu yakin untuk membuang {{count}} gambar? Aksi ini tidak dapat dibatalkan',
    'giftShop:modalSetAsMain':
      'Apakah kamu yakin untuk menetapkan akun ini sebagai akun utama kamu?',
    'giftShop:modalTitleDeleteBankAccount': 'Hapus Rekening Bank',
    'giftShop:modalTitleRemoveFromMain': 'Buang dari Akun Utama',
    'giftShop:modalTitleSetAsMain': 'Tetapkan sebagai Utama',
    'giftShop:moreDetail': 'Detail Tambahan',
    'giftShop:myBalance': 'Saldo Saya',
    'giftShop:myCollection': 'Koleksi Saya',
    'giftShop:myCreation': 'Kreasi Saya',
    'giftShop:hostCreationEmptyTitle':
      'Sepertinya kreator belum mengunggah konten apa pun...',
    'giftShop:myCreationEmptyTitle':
      'Kelihatannya kamu belum mengunggah konten apapun...',
    'giftShop:myCreationEmptyTitle_available': 'Selamat!',
    'giftShop:myCreationEmptyTitle_sold': 'Belum ada konten yang terjual',
    'giftShop:myCreationEmptyTitle_resend': 'Selamat!',
    'giftShop:hostCreationEmptyDesc':
      'Minta kreator untuk mengunggah konten mereka, dan konten akan ditampilkan di sini.',
    'giftShop:myCreationEmptyDesc':
      'Unggah konten pertama kamu, dan konten akan ditampilkan di sini.',
    'giftShop:myCreationEmptyDesc_available':
      'Semua konten kamu terjual. Kami senang untukmu!',
    'giftShop:myCreationEmptyDesc_sold':
      'Jangan khawatir, kontenmu luar biasa. Tetap semangat!',
    'giftShop:myCreationEmptyDesc_resend':
      'Kamu telah mengirim ulang semua konten. Kerja yang baik!',
    'giftShop:myCreationDownloadActivateDesc':
      'Aktifkan akun kreator kamu melalui aplikasi seluler FOTOYU.',
    'giftShop:myCreationDownloadDesc':
      'Buat dunia terkesan dengan kreasi kamu, berinteraksi dengan penggemar kamu, sambil menghasilkan uang!',
    'giftShop:myCreationDownloadTitle': 'Jadi seorang kreator',
    'giftShop:myCreationNotCompletedDesc':
      'Terima kasih atas data verifikasi kamu, sayang sekali prosesnya belum lengkap. Pastikan semua persyaratan sudah dipenuhi dengan benar dan coba lagi.',
    'giftShop:myCreationNotCompletedTitle': 'Pendaftaran Kreator Belum Lengkap',
    'giftShop:myCreationProcessedDesc':
      'Terima kasih atas data verifikasi kamu, sayang sekali kami tidak dapat memprosesnya saat ini.',
    'giftShop:myCreationProcessedTitle':
      'Pendaftaran Kreator Tidak Dapat Diproses',
    'giftShop:myCreationRecievedDesc':
      'Terima kasih atas data verifikasi kamu, kami masih melakukan review dan akan memberitahu kamu dalam waktu 24 jam',
    'giftShop:myCreationRecievedTitle': 'Verifikasi Kreator',
    'giftShop:myProfile': 'Profil Saya',
    'giftShop:myWishlist': 'Daftar Keinginan Saya',
    'giftShop:newBankAccount': 'Rekening Bank Baru',
    'giftShop:newNickname': 'Nickname Baru',
    'giftShop:newNicknamePlaceholder': 'Masukkan nickname baru',
    'giftShop:newPassword': 'Kata Sandi Baru',
    'giftShop:newPasswordPlaceholder': 'Masukkan kata sandi baru kamu',
    'giftShop:newUsername': 'Username Baru',
    'giftShop:newUsernamePlaceholder': 'Masukkan username baru',
    'giftShop:newYuser': 'Yuser Baru (@{{yuser}})',
    'giftShop:newYuserNoticeDescriptionFirst':
      'Selamat datang di Tab FotoYu, kamu akan menemukan fotomu di sini.',
    'giftShop:newYuserNoticeDescriptionSecond':
      'Namun, ada kemungkinan fotografer kamu belum mengunggah fotomu ke server.',
    'giftShop:newYuserNoticeDescriptionThird':
      'Bila foto kamu belum ada, harap cek berkala setelah beberapa jam.',
    'giftShop:newYuserNoticeTitle': 'Halo Yuser!',
    'giftShop:nicknameRecentlyChanged':
      'Nickname baru saja diganti. Mohon coba lagi nanti.',
    'giftShop:noBankAccountSubtitle':
      'Kamu bisa menambahkan {{max}} rekening bank di sini.',
    'giftShop:noBankAccountTitle': 'Kamu belum menambahkan rekening bank',
    'giftShop:noBio': 'Tidak Ada Bio',
    'giftShop:nominal': 'Nominal',
    'giftShop:nominalLabel': 'Nominal',
    'giftShop:noContent': 'Sepertinya kamu belum memiliki konten apa pun...',
    'giftShop:notHires': 'Belum Hi-Res',
    'giftShop:notificationUnread': '{{count}} Belum terbaca',
    'giftShop:noTransaction':
      'Sepertinya kamu belum melakukan transaksi apa pun...',
    'giftShop:noTransactionSub':
      'Riwayat pembelian kamu akan ditampilkan di sini.',
    'giftShop:noWebsite': 'Belum Ada Tautan',
    'giftShop:OTPVerification': 'Verifikasi OTP',
    'giftShop:OTPVerificationDescription':
      'Masukkan OTP yang dikirim ke {{number}}',
    'giftShop:OTPVerificationDescription_whatsapp':
      'Masukkan OTP yang dikirim ke {{number}} WhatsApp',
    'giftShop:pageNotFoundTitle': 'Ups! Halaman tidak ditemukan',
    'giftShop:pageNotFoundDescription':
      'Maaf halaman yang kamu minta tidak dapat ditemukan.',
    'giftShop:partnership': 'Kerja Sama',
    'giftShop:partnershipSuccess':
      'Terima kasih atas minat kerja sama dengan kami! Akan segera kami balas pesanmu.',
    'giftShop:partnershipNamePlaceholder': 'Ketik nama lengkap',
    'giftShop:partnershipMessagePlaceholder': 'Tulis pesan di sini',
    'giftshop:passwordConfirmation': 'Konfirmasi Kata Sandi',
    'giftShop:paymentInstruction': 'Instruksi Pembayaran',
    'giftShop:paymentFee': 'Biaya Pembayaran',
    'giftShop:paymentMethod': 'Metode Pembayaran',
    'giftShop:paymentProofTitle': 'Kirim Bukti Transfer',
    'giftShop:paymentProofAlreadySent': 'Harap menunggu verifikasi.',
    'giftShop:paymentSendProofFailed':
      'Gagal mengirim bukti transfer. Silakan coba lagi.',
    'giftShop:paymentSendProofSuccess': 'Bukti transfer telah terkirim.',
    'giftShop:paymentWaitOrSendProofFirst':
      'Bila kamu telah melakukan pembayaran, harap menunggu 2 menit untuk pemrosesan, atau ',
    'giftShop:paymentWaitOrSendProofSecond': 'kirim bukti transfer',
    'giftShop:paymentWaitOrSendProofUploadSubtitle':
      'File .png atau .jpeg, ukuran maksimal adalah 2MB',
    'giftShop:pendingTransaction': 'Transaksi Tertunda',
    'giftShop:photoCompress': 'Kompres Foto: ',
    'giftShop:photoResend': 'Kirim Ulang Foto: ',
    'giftShop:pinpointTheLocation': 'Geser peta untuk Menentukan lokasi',
    'giftShop:playRobopet': 'RoboYu',
    'giftShop:point': '{{count}} Poin',
    'giftShop:point_plural': '{{count}} Poin',
    'giftShop:premium': 'Premium',
    'giftShop:preparingContent': 'Menyiapkan konten terbaik untuk kamu...',
    'giftShop:previewInfoLabel':
      'Ini adalah versi preview. Kreator akan mengirim ulang file Hi-Res dalam waktu 2 hari. Kamu juga bisa chat dengan kreator menggunakan fitur pesan.',
    'giftShop:previewInfoLabel_photo':
      'Ini adalah versi preview. Kreator akan mengirim ulang file Hi-Res dalam waktu 2 hari. Kamu juga bisa chat dengan kreator menggunakan fitur pesan.',
    'giftShop:previewInfoLabel_video':
      'Ini adalah versi preview. Kreator akan mengirim ulang file dengan durasi lebih panjang dalam waktu 2 hari. Kamu juga bisa chat dengan Kreator menggunakan fitur pesan.',
    'giftShop:price': 'Harga',
    'giftShop:priceBreakdown': 'Rincian Harga',
    'giftShop:priceBreakdownRefund': 'Pengembalian',
    'giftShop:priceBreakdownRefund_percentage':
      '({{percentage}}% dari harga dasar)',
    'giftShop:priceBreakdownRefundCreator':
      'Pengembalian Kreator ({{percentage}}%)',
    'giftShop:priceBreakdownRefundFotoyu':
      'Pengembalian Fotoyu ({{percentage}}%)',
    'giftShop:priceBreakdownRefundHost': 'Pengembalian Host ({{percentage}}%)',
    'giftShop:priceEarn': 'Pendapatan Kamu: ',
    'giftShop:pricefee': 'Harga Dasar ({{count}}%)',
    'giftShop:pricefee_forky': 'Biaya FotoYu ({{count}}%)',
    'giftShop:pricefee_host': 'Biaya Host ({{count}}%)',
    'giftShop:pricefee_paymentservice': 'Biaya pembayaran & layanan',
    'giftShop:pricefee_paymentservicepercentage':
      '({{count}}% dari harga jual)',
    'giftShop:priceNet': 'Net Kreator ({{count}}%)',
    'giftshop:pricingDetail': 'Detail Harga',
    'giftShop:problemWhenProcessingRequest':
      'Ada masalah saat memproses permintaan kamu.',
    'giftshop:problemRequestAttemptLimit':
      'Kamu telah melewati batas percobaan edit. Mohon mencoba lagi nanti.',
    'giftShop:processImage': 'Memproses gambar: {{count}}/{{total}}',
    'giftShop:profileAddCredential':
      'Untuk mempermudah dukungan, mohon tambahkan nomor WhatsApp kamu.',
    'giftShop:profileAddCredential_email':
      'Untuk mempermudah dukungan, mohon tambahkan Email kamu.',
    'giftShop:profileHeader': 'Kreasi Saya',
    'giftShop:profilePhoto': 'Foto Profil',
    'giftShop:profileRemoveContentsMessage':
      'Kamu yakin ingin menghapus konten ini? Tindakan ini tidak bisa dibatalkan',
    'giftShop:purchase': 'Beli',
    'giftShop:purchaseDetail': 'Detail Pembelian',
    'giftshop:received': 'Penerimaan',
    'giftshop:receivedDetail': 'Detail Penerimaan',
    'giftshop:receivedPayment': 'Penerimaan Pembayaran',
    'giftShop:receivedtransaction':
      'Riwayat pembayaran yang kamu terima akan ditampilkan di sini.',
    'giftShop:recoverContentButton': 'Pulihkan',
    'giftShop:recoverContentButtonLabel': 'Pulihkan Konten?',
    'giftShop:recoverContentSuccessMessage': 'Konten berhasil dipulihkan.',
    'giftShop:removeAllSeriesMessage':
      'Apakah kamu yakin ingin menghapus seluruh seri? Aksi ini tidak dapat dibatalkan.',
    'giftShop:removeCart': 'Buang dari Keranjang',
    'giftShop:removeCartSuccessMessage':
      'Konten berhasil dihapus dari keranjang.',
    'giftShop:removeContents': 'Hapus Konten',
    'giftShop:removeContentsMessage':
      'Apakah kamu yakin untuk menghapus {{count}} konten? Aksi ini tidak dapat dibatalkan',
    'giftShop:removeContentsMessage_plural':
      'Apakah kamu yakin untuk menghapus {{count}} konten? Aksi ini tidak dapat dibatalkan',
    'giftShop:removeContentsSnackbarFailed': 'Gagal menghapus konten.',
    'giftShop:removeContentsSnackbarFailed_autopost':
      'Maaf kamu tidak dapat menghapus konten yang dibagikan dengan lebih dari satu pengguna.',
    'giftShop:removeContentsSnackbarSuccess': 'Konten berhasil dihapus.',
    'giftShop:removedContentEmptySubtitle':
      'Semua konten yang dihapus akan ditampilkan di sini.',
    'giftShop:removedContentEmptyTitle':
      'Sepertinya kamu belum menghapus konten apa pun.',
    'giftShop:removedContentSubtitle': 'Konten akan dihapus setelah 7 hari',
    'giftShop:removedContentTitle': 'Konten Terhapus',
    'giftShop:removeMainAccountFailed': 'Gagal membuang akun dari akun utama',
    'giftShop:removeMainAccountSuccess': 'Akun sukses dibuang dari akun utama!',
    'giftShop:removePicture': 'Buang Gambar',
    'giftShop:removeProfilePictureModalDesc':
      'Apakah kamu yakin ingin membuang gambar?',
    'giftShop:removeWishlistModalDesc':
      'Apakah kamu yakin ingin menghapus {{count}} gambar? Aksi ini tidak dapat dibatalkan.',
    'giftShop:reportReason': 'Tidak diketahui',
    'giftShop:reportReason_FAKE ACCOUNT': 'Berpura-pura menjadi orang lain',
    'giftShop:reportReason_INAPPROPRIATE':
      'Salah satu konten yang diposting tidak sesuai',
    'giftShop:reportReason_OTHER': 'Lainnya',
    'giftShop:reportReason_SPAM': 'Konten spam',
    'giftShop:resendBatchFailedDescription':
      'Terjadi kesalahan, silakan coba lagi.',
    'giftShop:resendBatchFailedTitle': 'Gagal Mengunggah',
    'giftShop:resendBatchNoDate': 'Tanggal Kosong',
    'giftShop:resendBatchNoFileName': 'Nama File Kosong',
    'giftShop:resendBatchNoFileSize': 'Ukuran File Kosong',
    'giftShop:resendBatchNoResolution': 'Resolusi Kosong',
    'giftShop:resendBatchPhotoPlaceholder': 'Tambahkan versi resolusi tinggi',
    'giftShop:resendContentCount': '{{count}} Konten',
    'giftShop:resendContentCount_plural': '{{count}} Konten',
    'giftShop:resendFloatingButtonTitle': 'Permintaan kirim ulang baru',
    'giftShop:resendFloatingButtonSubtitle': 'Tekan untuk melihat',
    'giftShop:resend': 'Kirim Ulang',
    'giftShop:resendEmail': 'Kirim ulang email',
    'giftShop:resendMinSize': 'File gambar minimum adalah {{min}}',
    'giftShop:resendOTP': 'Kirim OTP lagi',
    'giftShop:resendRevisionButton': 'Kirim Ulang Revisi',
    'giftShop:resendRevisionChatExpired':
      'Maaf, waktu untuk mengonfirmasi konten beresolusi tinggi telah lewat.',
    'giftShop:resendRevisionErrorAlreadyRevisedMessage':
      'Maaf! Konten ini sudah pernah kamu revisi sebelumnya.',
    'giftShop:resendRevisionImagePlaceholder':
      'Tambahkan versi resolusi tinggi',
    'giftShop:resendRevisionModalEmpty':
      'Tidak ada Yuser yang dapat kamu kirimkan revisi.',
    'giftShop:resendRevisionModalSubtitle':
      'Daftar yuser di bawah ini adalah mereka yang telah membeli konten ini.',
    'giftShop:resendRevisionModalMessage':
      '(Revisi hanya tersedia selama 24 jam setelah Hi-Res)',
    'giftShop:resendRevisionModalTitle': 'Pilih Yuser',
    'giftShop:resendStatus': 'Kirim Ulang: ',
    'giftShop:resendToYuser': 'Kirim ulang ke Yuser',
    'giftShop:resetPasswordPromptMessage':
      'Apakah kamu yakin ingin membatalakan? Data tidak akan disimpan',
    'giftShop:resolution': 'Resolusi:',
    'giftShop:retailDescription':
      '1. Sampaikan kepada kasir Alfamart/Alfamidi untuk melakukan pembayaran ke merchant PT. Super Giga Generasi (SUPER GIGA). \n2. Tunjukan kode pembayaran ke kasir dan lakukan pembayaran sebesar {{Amount}}. \n3.  Setelah transaksi berhasil, kamu akan mendapatkan bukti pembayaran. Mohon simpan bukti pembayaran sebagai jaminan apabila diperlukan verifikasi lebih lanjut. \n4. Pesanan kamu akan terverifikasi secara otomatis dalam aplikasi FotoYu App setelah pembayaran berhasil',
    'giftShop:retailDescription_fourth':
      '4. Pesanan kamu akan terverifikasi secara otomatis dalam aplikasi FOTOYU setelah pembayaran berhasil',
    'giftShop:retailDescription_second':
      '2.  Tunjukan kode pembayaran ke kasir dan lakukan pembayaran sebesar {{Amount}}.',
    'giftShop:retailDescription_third':
      '3. Setelah transaksi berhasil, kamu akan mendapatkan bukti pembayaran. Mohon simpan bukti pembayaran sebagai jaminan apabila diperlukan verifikasi lebih lanjut.',
    'giftShop:retakeKYC': 'Tambah Selfie',
    'giftShop:retakeKYCExample': 'Contoh Yang Benar & Salah:',
    'giftShop:retakeKYCInstruction': 'Ketuk layar untuk melanjutkan',
    'giftShop:retakeKYCMessage':
      'Selfie yang kamu daftarkan kurang jelas. Silakan tambahkan selfie dengan mengikuti tips yang diberikan.',
    'giftShop:retakeKYCPlaceholder':
      'Kamu dapat menambahkan 1 selfie lagi.\nKamu boleh memakai kacamata.',
    'giftShop:retakeKYCPlaceholder_processing':
      'RoboYu mempelajari selfie baru.\nMohon tunggu...',
    'giftShop:retakeKYCPlaceholder_rejected':
      'RoboYu membutuhkan selfie yang lebih baik, silakan ambil kembali dengan mengikuti tips yang diberikan.',
    'giftShop:retakeKYCRoboyuMessage':
      'Halo Bos, RoboYu mencari foto kamu berdasarkan selfie ini.',
    'giftShop:retakeKYCWarning':
      'Pastikan wajahmu bersih dan tidak tertutup oleh aksesori (selain kacamata) dan rambut',
    'giftShop:robopet': 'RoboYu',
    'giftShop:robopetHeader': '',
    'giftShop:roboyuAccuracyHighDesc': 'Konten lebih sedikit',
    'giftShop:roboyuAccuracyMediumDesc': 'Konten lebih banyak',
    'giftShop:roboyuAccuracySuccessSnackbar': 'Akurasi RoboYu disesuaikan.',
    'giftShop:roboyuAccuracySuccessSnackbar_content':
      'Menampilkan lebih banyak konten...',
    'giftShop:roboyuAccuracyTitle': 'Akurasi Pencarian RoboYu',
    'giftShop:roboyuAccuracyPlus': 'Akurasi 5+',
    'giftShop:roboyuAccuracyMinus': 'Akurasi 5-',
    'giftShop:roboyuActivationIncompleteDescription':
      'Maaf! Sepertinya prosesnya belum selesai. Pastikan semua persyaratan sudah benar, dan coba lagi.',
    'giftShop:roboyuActivationIncompleteHeader': 'Verifikasi belum lengkap',
    'giftShop:roboyuActivationProcessingDescription':
      'Terima kasih! RoboYu akan memberitahu kamu secepatnya.',
    'giftShop:roboyuActivationProcessingHeader': 'Menunggu Persetujuan...',
    'giftShop:roboyuActivationRejectedDescription':
      'Terima kasih untuk pengumpulannya! Namun, kami belum dapat memprosesnya untuk saat ini.',
    'giftShop:roboyuActivationRejectedHeader':
      'Verifikasi Tidak Dapat Diproses',
    'giftShop:roboyuActivationUnregisteredDescription':
      'Aktifkan RoboYu melalui aplikasi mobile FOTOYU.',
    'giftShop:roboyuActivationUnregisteredDescription_search':
      'Kamu dapat mengaktifkan RoboYu sebagai asisten robo pencarian kamu.',
    'giftShop:roboyuActivationUnregisteredDescription_robopet':
      'Gunakan teknologi pengenalan wajah di RoboYu untuk menemukan konten kamu lebih cepat.',
    'giftShop:roboyuActivationUnregisteredDescription_filter-ol':
      'Filter pencarian kamu berdasarkan lokasi dan riwayat waktu.',
    'giftShop:roboyuActivationUnregisteredDescription_image-search':
      'Kamu juga dapat mencari berdasarkan gambar, RoboYu dapat mengenali benda apa pun yang ada pada gambar.',
    'giftShop:roboyuActivationUnregisteredHeader': 'Hai! Aku RoboYu!',
    'giftShop:roboyuActivationVerifiedDescription':
      'RoboYu mulai mencari segera dan akan mengirimimu DM jika menemukan sesuatu. Untuk pencarian pertama kali, RoboYu membutuhkan waktu verifikasi. Kontenmu akan ditampilkan pada tab "FotoYu".',
    'giftShop:roboyuActivationVerifiedHeader': 'RoboYu Diaktifkan',
    'giftShop:roboyuActivationUploadPhotoDescription':
      'Unggah kreasi fotomu ke server FotoYu agar bisa ditemukan oleh teman atau keluargamu.',
    'giftShop:roboyuFirstBuyBannerTitle': 'Program Rujukan Pembelian Pertama',
    'giftShop:roboyuFirstBuyBannerDescription':
      'Ajak teman dan keluarga kamu untuk mendapatkan tambahan penyimpanan 50 MB/yuser baru yang membeli pertama kali di FotoYu dari kamu.',
    'giftShop:roboyuIncompleteDesc':
      'Mohon pastikan semua persyaratan sudah sesuai, dan coba lagi.',
    'giftShop:roboyuIncompleteSubtitle':
      'Proses Verifikasi KYC kamu Tidak Sesuai.',
    'giftShop:roboyuReadMore': 'Baca selengkapnya',
    'giftShop:roboyuUploadInvitation':
      'Unggah kreasi fotomu ke server FotoYu agar bisa ditemukan oleh teman atau keluargamu.',
    'giftShop:roboyuUploadNotActivatedDesc':
      'Kamu harus mengaktifkan RoboYu kamu terlebih dahulu sebelum mengunggah konten pertama kamu.',
    'giftShop:roboyuUploadNotActivatedInfo':
      'Saat RoboYu kamu diaktifkan, kamu dapat kembali ke sini untuk mulai mengunggah konten kamu.',
    'giftShop:searchByCreatorEmptyInfo':
      'Silakan coba lagi menggunakan kata kunci lain.',
    'giftShop:searchByCreatorEmptyTitle':
      'Maaf! Kami tidak dapat menemukan Kreator yang kamu cari',
    'giftShop:searchByCreatorNotFoundButton': 'Buka Tab FotoYu',
    'giftShop:searchByCreatorNotFoundDescription1':
      'Silakan minta pemilik tautan untuk memperbarui tautannya,',
    'giftShop:searchByCreatorNotFoundDescription2':
      ' atau gunakan tombol cari di tab FotoYu.',
    'giftShop:searchByCreatorNotFoundTitle':
      'Maaf! Kami tidak menemukan si kreator',
    'giftShop:searchByCreatorPlaceholder': 'Cari berdasarkan username kreator',
    'giftShop:searchCityPlaceholder': 'Ketik nama kota',
    'giftShop:searchCreation': 'Cari Konten',
    'giftShop:searchCreationNotFoundTitle':
      'Maaf! Kami tidak dapat menemukan konten yang kamu cari',
    'giftShop:searchCreationNotFoundMessage':
      'Silakan coba lagi menggunakan kata kunci lain.',
    'giftShop:searchCreationPlaceholder': 'Ketik nama konten (Min. 3 karakter)',
    'giftShop:searchCreatorAndFotoTreeNotFound':
      'Maaf! Kami tidak dapat menemukan hasil yang kamu cari. Silakan coba lagi menggunakan kata kunci lain.',
    'giftShop:searchCreatorAndFotoTreePlaceholder': 'FotoTree atau Kreator',
    'giftShop:searchInputMinThreeChar': 'Ketik (Min. 3 karakter)',
    'giftShop:searchLocationInputPlaceholder': 'Cari Lokasi',
    'giftShop:searchResultDisclaimerTitle': 'Temuan RoboYu kamu',
    'giftShop:searchResultDisclaimer1':
      '**Perlu diketahui bahwa FotoYu tidak pernah mencarikan foto untuk yuser. Yuserlah yang mengendalikan RoboYu masing-masing untuk mencari foto mereka sendiri.**',
    'giftShop:searchResultDisclaimer2':
      'RoboYu adalah Robo yang cerdas dan rajin. Bagaimanapun, **kamu adalah pengontrolnya**. Jadi, untuk setiap foto yang ditemukan, RoboYu akan meminta konfirmasi kamu, **apakah ini kamu?** Kamu hanya butuh menjawab: **Ya** atau **Bukan**.',
    'giftShop:searchResultDisclaimer3':
      'Dari jutaan foto di server, RoboYu hanya akan menawarkan yuser, foto yang dianggap cocok dengan **algoritma wajah dan lokasi**. Ini akan secara drastis mengurangi kemungkinan foto orang lain muncul di pencarian kamu.',
    'giftShop:searchResultDisclaimer4':
      'Terkadang RoboYu akan menawarkan foto yang dianggap mirip **secara algoritma**, tapi mungkin bukan kamu. Secara etis, kamu hanya mengonfirmasi jika foto itu benar-benar kamu, dan menolak foto yang bukan kamu. Dengan menjawab **Ya** atau  **Bukan** dengan benar, kamu melatih RoboYu untuk menjadi **lebih pintar dan lebih efisien** dalam menemukan foto kamu.',
    'giftShop:searchResultDisclaimer5':
      '**Kamu tidak boleh membeli foto orang lain di FotoYu, jika tidak ada kamu di foto tersebut, kami tegaskan.**',
    'giftShop:searchResultDisclaimer6':
      'Semua transaksi akan meninggalkan jejak digital di mana FotoYu akan tegakkan secara maksimal untuk melindungi privasi semua orang.',
    'giftShop:searchResultDisclaimer7':
      'Banyak situs web lain mengizinkan kamu untuk membeli foto orang lain, tetapi kami melarangnya di FotoYu.',
    'giftShop:searchResultDisclaimer8':
      'FotoYu mencoba membuat perbedaan nyata dalam privasi, sehingga proses penjualan foto/konten pribadi di dunia ini, tidak perlu menampilkan foto semua orang untuk dilihat semua orang',
    'giftShop:searchResultDisclaimerCloser': 'Salam FotoYu!',
    'giftShop:searchTransaction': 'Cari Transaksi',
    'giftShop:searchTransactionInputPlaceholder':
      'Bank, invoice ID, atau kreator (Min. 3 karakter)',
    'giftShop:selectFile': 'Pilih File',
    'giftShop:selectItems': 'Terpilih ({{count}})',
    'giftShop:selectVerificationMethod': 'Pilih Metode Verifikasi',
    'giftShop:sell': 'Jual',
    'giftShop:sendToFace': 'Kirim ke Wajah',
    'giftShop:sendToFaceDescriptionPlaceholder': 'Tulis sebuah deskripsi',
    'giftShop:sendToFaceInfo': 'Secara otomatis mengirim foto ke pemilik wajah',
    'giftShop:setAsMainBankAccount': 'atur sebagai rekening bank utama',
    'giftShop:setting': 'Pengaturan Akun',
    'giftShop:settingHeader': 'Pengaturan Akun',
    'giftShop:settingWholesale': 'Pengaturan Diskon',
    'giftShop:settingWholesaleDisclaimer':
      'Diskon akan diterapkan ke harga dasar konten.',
    'giftShop:shareCollectionTextTemplateTwitterAndFacebook':
      'Hi, lihat konten keren yang saya temukan dari Situs FotoYu!%0ATemukan lebih banyak lagi di https://www.fotoyu.com %0A',
    'giftShop:shareCollectionTextTemplateWhatsapp':
      'Hi, lihat konten keren yang saya temukan dari Situs FotoYu!%0ATemukan lebih banyak lagi di https://www.fotoyu.com %0A%0A',
    'giftShop:shareProfile': 'Bagikan Profil',
    'giftShop:shareProfileFotoyu': 'Bagikan Profil FotoYu',
    'giftShop:shareProfileFotoyuDescription':
      'Agar yuser bisa melihat portofolio kamu.',
    'giftShop:shareProfileSearchLink': 'Bagikan Link Pencarian',
    'giftShop:shareProfileSearchLinkCreatorOnlySnackbar':
      'Mohon tingkatkan akun kamu menjadi kreator terverifikasi.',
    'giftShop:shareProfileSearchLinkDescription':
      'Agar yuser dapat melihat hanya konten kamu.',
    'giftShop:shareTo': 'Bagikan ke...',
    'giftShop:similarAccountDeleteOthersDesc':
      'Harap hapus akun kamu yang lain untuk membantu mempercepat verifikasi akun kamu. Akun ganda yang tidak dihapus membutuhkan waktu untuk verifikasi manual admin:',
    'giftShop:similarAccountDeleteSuccess': 'Akun berhasil dihapus.',
    'giftShop:similarAccountDescSelf':
      'Setiap orang hanya boleh memiliki 1 akun.',
    'giftShop:similarAccountNoOtherAccount': 'Saya tidak memiliki akun lain',
    'giftShop:similarAccountSelfConfirm': 'Apakah ini akun kamu?',
    'giftShop:similarAccountSelfVerified':
      'Akun kamu telah berhasil diaktifkan dan siap untuk digunakan. Selamat bergabung!',
    'giftShop:similarChooseAccountDesc':
      'Setiap orang hanya boleh memiliki 1 akun. Harap pilih salah satu akun yang ingin kamu aktifkan:',
    'giftShop:similarChooseAccountSelfText':
      'Harap menunggu sembari kami melakukan verifikasi. Kami akan mengirimkan pemberitahuan setelah selesai.',
    'giftShop:similarCurrentAccount': 'Saat ini',
    'giftShop:similarIdentityDescription':
      'Sebagai antisipasi, kami membutuhkan verifikasi manual untuk konfirmasi ulang.',
    'giftShop:similarIdentitySubtitle':
      'RoboYu mendeteksi adanya kemiripan dengan akun lain.',
    'giftShop:similarOtherAccountFailedMessage':
      'Email tidak valid, harap lengkapi secara benar.',
    'giftShop:similarOtherAccountFailedMessage_phone':
      'Nomor ponsel tidak valid, harap lengkapi secara benar.',
    'giftShop:similarOtherAccountFailedMessage_username':
      'Username tidak valid, harap lengkapi secara benar.',
    'giftShop:similarOtherAccountFinish':
      'Kamu akan ter-log out, dan akun ini akan diblokir. Harap login dengan akun yang kamu pilih tadi.',
    'giftShop:similarOtherAccountVerif':
      'Harap lengkapi email berikut untuk verifikasi.',
    'giftShop:similarOtherAccountVerif_phone':
      'Harap lengkapi nomor ponsel berikut untuk verifikasi.',
    'giftShop:similarOtherAccountVerif_username':
      'Harap lengkapi username berikut untuk verifikasi.',
    'giftShop:similarOtherAccountVerifUsernamePlaceholder': 'Masukkan username',
    'giftShop:similarWaitingApprovalDesc':
      'Kami akan memberi tahu kamu setelah proses selesai.',
    'giftShop:similarWaitingApprovalSubtitle':
      'Aktivasi Roboyu sedang menunggu verifikasi manual. Harap jangan membuat akun kedua.',
    'giftShop:snackbarFailedDownloadMyCollection':
      'Unduhan gagal. Silahkan coba lagi nanti',
    'giftShop:snackbarFailedExecute':
      'Terjadi kesalahan saat mengeksekusi tindakan. Silahkan coba lagi.',
    'giftShop:snackbarFailedFetch':
      'Terjadi kesalahan saat menarik data. Silahkan coba lagi.',
    'giftShop:snackbarSuccessExecute': 'Berhasil mengeksekusi tindakan',
    'giftShop:snackbarSuccessExecute_add':
      'Barang berhasil dimasukkan ke {{where}}',
    'giftShop:snackbarSuccessExecute_remove':
      'Barang berhasil dibuang dari {{where}}',
    'giftShop:socialLink': 'Tautan Sosial',
    'giftShop:socialLink_add': 'Tambah Tautan Sosial',
    'giftShop:socialLink_edit': 'Sunting Tautan Sosial',
    'giftShop:socialLinkDescription': 'Kamu dapat menambahkan hingga 6 tautan',
    'giftShop:socialLinkDescriptionEmpty': 'Kamu tidak mempunyai tautan apapun',
    'giftshop:socialLinkResponseSuccess':
      'Tautan kamu telah berhasil ditambahkan.',
    'giftshop:socialLinkResponseSuccess_edit':
      'Tautan kamu berhasil disunting.',
    'giftshop:socialLinkResponseSuccess_delete':
      'Tautan kamu berhasil dihapus.',
    'giftshop:socialLinkValidation': 'Field tidak boleh kosong',
    'giftshop:socialLinkValidation_minimumLength':
      'Username harus terdiri dari minimal {{length}} karakter.',
    'giftshop:socialLinkValidation_minimumNumberLength':
      'Nomor ponsel harus terdiri dari minimal {{length}} karakter.',
    'giftshop:socialLinkValidation_includeKeyword':
      'Link harus mengandung {{keyword}}',
    'giftShop:sold': 'Terjual',
    'giftShop:status': 'Status',
    'giftShop:storage': 'Penyimpanan',
    'giftShop:storageInfo': 'Info Penyimpanan',
    'giftShop:storageFullInfo': 'Penyimpanan kamu sudah penuh.',
    'giftShop:storageFullTitle': 'Penyimpanan kamu sudah penuh',
    'giftShop:storageFullMessage':
      'Kamu dapat menghubungi Admin untuk penyimpanan tambahan.',
    'giftShop:storageLowInfo': 'Penyimpanan kamu hampir penuh.',
    'giftShop:storageLowInfoNonVerified':
      'Penyimpanan kamu hampir penuh. Tingkatkan akun kamu menjadi kreator terverifikasi dan nikmati penyimpanan yang lebih besar.',
    'giftShop:storageRequest': 'Minta Penyimpanan',
    'giftShop:storageRequestNotice':
      'Kami telah menerima permintaan kamu. Mohon tunggu pemberitahuan dari kami.',
    'giftShop:successAddPassword': 'Password kamu telah ditambahkan.',
    'giftShop:successDownloadMessageHires':
      'Terunduh. Cek folder "FotoYu" di galerimu.',
    'giftShop:successDownloadMessageNotHires':
      'Versi "Bukan Hi-Res" terunduh. Cek folder "FotoYu" di galerimu.',
    'giftShop:successfullyAddedItem': 'Sukses menambah barang',
    'giftShop:supportTitle': 'Punya pertanyaan?',
    'giftShop:supportSubTitle': 'Kamu dapat menghubungi tim support FotoYu',
    'giftShop:successUnblockedAccount': 'Akun berhasil dibuka blokirnya',
    'giftShop:successfulTransaction': 'Transaksi Sukses',
    'giftShop:tag': 'FotoTree:',
    'giftShop:textTo': 'SMS ke',
    'giftShop:totalBalance': 'Total Saldo',
    'giftShop:totalIncentive': 'Total Insentif',
    'giftShop:totalItems': '{{count}} konten',
    'giftShop:totalPayment': 'Total Pembayaran',
    'giftShop:totalPrice': 'Total Harga',
    'giftShop:totalRefund': 'Total Pengembalian Dana',
    'giftShop:totalRefundAmount': '{{price}} ({{percent}}%)',
    'giftShop:totalWithdraw': 'Total Penarikan',
    'giftShop:transactionCreatorName': 'Nama Kreator',
    'giftShop:transactionCreatorNameHost': 'Nama Host',
    'giftShop:transactionDate': 'Waktu Transaksi',
    'giftShop:transactionDetail': 'Detail Transaksi',
    'giftShop:transactionDetailSubtitle': 'Halaman ini bukan bukti pembayaran.',
    'giftShop:transactionHistory': 'Riwayat Transaksi',
    'giftShop:transactionHistoryNotFoundDescription':
      'Silakan coba lagi menggunakan kata kunci lain.',
    'giftShop:transactionHistoryNotFoundTitle':
      'Maaf! Kami tidak dapat menemukan transaksi yang kamu cari',
    'giftShop:transactionPaymentTime': 'Waktu Pembayaran',
    'giftShop:transactionStatus': 'Tidak Diketahui',
    'giftShop:transactionStatus_COMPLETED': 'Selesai',
    'giftShop:transactionStatus_FAILED': 'Gagal',
    'giftShop:transactionStatus_HALF_COMPLETED': 'Setengah Selesai',
    'giftShop:transactionStatus_PROCESSED': 'Menunggu Pembayaran',
    'giftShop:transactionStatus_WAITING_HIRES': 'Menunggu Hi-Res',
    'giftShop:transactionTime': 'Waktu Transaksi',
    'giftShop:transactionType': 'Tipe Transaksi',
    'giftShop:transactionType_INCENTIVE': 'Insentif',
    'giftShop:transactionType_PURCHASE': 'Pembayaran kepada',
    'giftShop:transactionType_RECEIVED': 'Pembayaran dari',
    'giftShop:transactionType_REFUND': 'Pengembalian dari',
    'giftShop:transactionType_REFUND_TO': 'Pengembalian kepada',
    'giftShop:transactionType_MAKE_IT_FREE': 'Pengembalian gratis',
    'giftShop:transactionType_WITHDRAW': 'Penarikan',
    'giftShop:transactionVANumberReminder':
      'Nomor VA ini hanya bisa dipakai sekali. Saat pembayaran, gunakan layanan bank yang sama dengan yang dipilih.',
    'giftShop:transactionYuserNickname': 'Nickname Pembeli',
    'giftShop:twitter': 'Twitter',
    'giftShop:ofLimitStorage': ' dari ',
    'giftShop:gbStorage': '{{limit}} GB',
    'giftShop:usedStorage': ' digunakan',
    'giftShop:totalStorage': 'Total: {{limit}} GB',
    'giftShop:bonusStorage': '+{{bonus}} GB',
    'giftShop:unblock': 'Buka Blokir',
    'giftShop:unsatisfiedUploadFormat':
      'Gambar harus dibawah 50MB dan resolusi 25MP',
    'giftShop:unSuccessUnblockedAccount': 'Gagal buka blokir akun',
    'giftShop:unsatisfiedUploadFormat_SIZE_ONLY': 'Gambar harus dibawah 50MB',
    'giftShop:unwishlistModalConfirmText': 'Bukan, ini bukan saya',
    'giftShop:unwishlistModalDesc':
      'RoboYu akan terus berlatih untuk melayani kamu dengan lebih baik.',
    'giftShop:unwishlistModalQuestion': 'Apakah kamu yakin ini bukan kamu?',
    'giftShop:unwishlistModalTitle': 'Ups...',
    'giftShop:upgrade': 'Akun Premium',
    'giftShop:upgradeCreator': 'Tingkatkan ke Kreator',
    'giftShop:upgradeCreatorDescription':
      'Tingkatkan ke Kreator Verified melalui aplikasi seluler fotoyu',
    'giftShop:upgradeCreatorPrice': 'Atur harga foto kamu hingga 1 juta rupiah',
    'giftShop:upgradeCreatorStorage':
      'Kamu akan mendapatkan penyimpanan tambahan hingga 10 GB',
    'giftShop:upgradeCreatorVideo': 'Kamu akan dapat menjual video',
    'giftShop:upgradeDescription':
      'Tingkatkan akun kamu untuk mendapatkan semua fasilitas tambahan',
    'giftShop:upgradeToPremium': 'Tingkatkan ke Premium',
    'giftShop:uploadAddSeriesButton': '+ Orang Sama',
    'giftShop:uploadAddSeriesToolTip':
      'Tambahkan orang dengan informasi yang sama dengan gambar utama',
    'giftShop:uploadAnImage': 'Unggah gambar',
    'giftShop:uploadBulkDeleteConfirmationDescription':
      'Kamu yakin ingin menghapus konten ini? Tindakan ini tidak bisa dibatalkan',
    'giftShop:uploadBulkDeleteConfirmationHeader': 'Hapus Konten',
    'giftShop:uploadContentHeader': 'Unggah Konten',
    'giftShop:uploadContentHere': 'Unggah gambar kamu disini',
    'giftShop:uploadCriteria':
      'Gambar hanya boleh JPG, JPEG, PNG, HEIF, atau HEIC dan tidak boleh lebih dari 50MB',
    'giftShop:uploadDeleteContent': 'Hapus Konten',
    'giftShop:uploadDescriptionLabel': 'Deskripsi (Opsional)',
    'giftShop:uploadDragAndDrop':
      'Jatuhkan karya agung kamu di sini untuk membuatnya terbang',
    'giftShop:uploadDroptheImage': 'Tarik gambar kesini untuk mengunggahnya',
    'giftShop:uploadedContentCount': '{{count}} Konten',
    'giftShop:uploadedContentCount_plural': '{{count}} Konten',
    'giftShop:uploadedPicture': '{{count}} konten berhasil ditambahkan',
    'giftShop:uploadEmptyDesc':
      'Mulai meng-upload dan biarkan dunia tahu akan karyamu!',
    'giftShop:uploadEmptyTitle': 'Tempat ini sangat sepi...',
    'giftShop:uploadFailed': 'Terdapat kesalahan saat mengunggah gambar kamu',
    'giftShop:uploadFailed_storage':
      'Melebihi maksimum kapasitas penyimpanan {{limit}} GB',
    'giftShop:uploadIconMaxSnackbar': 'Maksimum {{max}} gambar',
    'giftShop:uploadIconMaxVideoSnackbar': 'Maksimum {{max}} video',
    'giftShop:uploadInputDateLabel': 'Tanggal (Opsional)',
    'giftShop:uploadInputLocationLabel': 'Lokasi (Opsional)',
    'giftShop:uploadInputDescriptionPlaceholder':
      'Ceritakan tentang konten kamu',
    'giftShop:uploadInputLocationPlaceholder': 'Tambahkan Lokasi',
    'giftShop:uploadInputPeopleTagPlaceholder': 'Maks. 10 Nicknames',
    'giftShop:uploadInputPricePlaceholder': 'Berapa Harganya?',
    'giftShop:uploadInputTagsNotFound': 'Kami tidak dapat menemukan FotoTree ',
    'giftShop:uploadInputTagsNotSelected': 'Mohon pilih FotoTree dari daftar.',
    'giftShop:uploadInputTagsPlaceholder': 'Ketik nama FotoTree',
    'giftShop:uploadInputTimeLabel': 'Waktu (Opsional)',
    'giftShop:uploadInputTitlePlaceholder': 'Nama dari mahakarya ini',
    'giftShop:uploadMaximumMessage':
      'Tidak bisa mengunggah lebih dari {{max}} foto',
    'giftShop:uploadMaximumVideoMessage':
      'Tidak bisa mengunggah lebih dari {{max}} video',
    'giftShop:uploadModalMessage':
      'Apakah kamu yakin ingin membatalkan? Konten tidak akan disimpan',
    'giftShop:uploadModalCompressDescriptionEnabled':
      'Kompres & Kirim Ulang telah Aktif',
    'giftShop:uploadModalPostDescription':
      'Bagikan momen kamu dan konten akan muncul secara publik di umpan Beranda.',
    'giftShop:uploadModalSellDescription':
      'Unggah kreasimu ke server Fotoyu, agar RoboYu milik Yuser bisa menemukannya.',
    'giftShop:uploadModalTitle': 'Pilih Tipe Konten',
    'giftShop:uploadModeBulkDescription':
      'Satu metadata untuk semua file (Maks. {{max}} file)',
    'giftShop:uploadModeBulkHeader': 'Foto - Mode Bulk',
    'giftShop:uploadModeSingleDescription':
      'Kustom metadata untuk tiap file (Maks. {{max}} file)',
    'giftShop:uploadModeSingleHeader': 'Foto - Mode Single',
    'giftShop:uploadModeVideoDescription':
      'Satu metadata untuk semua file (Maks. {{max}} file)',
    'giftShop:uploadModeVideoHeader': 'Pratinjau Video',
    'giftShop:uploadModeVideoSubdescription':
      'Gunakan editor video untuk menyesuaikan file dengan kriteria.',
    'giftShop:uploadNeedLocationOrFototreeErrorMessage':
      'Antara FotoTree atau Lokasi perlu diisi.',
    'giftShop:uploadPeopleTagLabel': 'Tag Nickname Yuser (Opsional)',
    'giftShop:uploadPhoto': 'Unggah Foto',
    'giftShop:uploadPriceEmpty': 'Mohon mengisi data harga',
    'giftShop:uploadPriceZeroModalButtonCancel': 'Ubah Harga',
    'giftShop:uploadPriceZeroModalDescription':
      'Beberapa konten memiliki label harga nol, apakah kamu yakin?',
    'giftShop:uploadPriceZeroModalTitle': 'Label Harga Nol',
    'giftShop:uploadPublishFinishedDescription':
      'Konten telah berhasil diunggah!',
    'giftShop:uploadPublishFinishedDescription_plural':
      'Semua konten telah berhasil diunggah!',
    'giftShop:uploadPublishFinishedTitle': 'Selesai!',
    'giftShop:uploadPublishLoadingDescription':
      '{{success}} dari {{total}} Konten yang Diunggah.',
    'giftShop:uploadPublishLoadingNote': 'Harap tunggu sebentar',
    'giftShop:uploadPublishLoadingTitle': 'Mengunggah...',
    'giftShop:uploadPublishReportTitle': 'Laporan Unggahan',
    'giftShop:uploadPublishReportDescription': 'Kamu baru saja mengunggah:',
    'giftShop:uploadPublishRetryCountFail': '{{count}} konten gagal diunggah.',
    'giftShop:uploadPublishRetryCountDuplicate':
      '{{count}} konten terdeteksi sebagai duplikat',
    'giftShop:uploadPublishRetryCountUploaded':
      '{{count}} konten berhasil diunggah.',
    'giftShop:uploadPublishRetryCountUploadedDuplicate':
      'Hanya {{count}} konten berhasil diunggah.',
    'giftShop:uploadPublishRetryTitle': 'Diunggah! Tetapi...,',
    'giftShop:uploadPublishRetryTryAgainAndViewReport':
      'Try Again, then View Report',
    'giftShop:uploadPublishRetryViewReport': 'Lihat Laporan',
    'giftShop:uploadQuickFillBanner':
      'Klik untuk mengisi formulir lebih cepat.',
    'giftShop:uploadQuickFillModalDescription': 'Deskripsi:',
    'giftShop:uploadQuickFillModalLocation': 'Lokasi:',
    'giftShop:uploadQuickFillModalPrice': 'Harga Dasar:',
    'giftShop:uploadQuickFillModalSuccessSnackbar': 'Data berhasil diisi.',
    'giftShop:uploadQuickFillModalTitle':
      'Isi semua data konten dengan data yang terakhir digunakan:',
    'giftShop:uploadSeriesModalTitle': 'Semua Konten dalam Seri ({{count}})',
    'giftShop:uploadSeriesModalTitle_plural':
      'Semua Konten dalam Seri ({{count}})',
    'giftShop:uploadSeriesSetAsParent': 'Jadikan Gambar Utama',
    'giftShop:uploadTagHostFee': 'Biaya Host: {{hostFee}}%',
    'giftShop:uploadTagHostFeeEditFotoTree': 'Mohon ubah FotoTree.',
    'giftShop:uploadTagHostFeeFototreeDesc':
      '(Biaya {{hostFee}}%) {{location}}',
    'giftShop:uploadTagHostFeeModalDescription':
      'Ada Biaya Host dari FotoTree yang kamu pilih, yang akan dipotong dari pendapatanmu. Apakah kamu yakin?',
    'giftShop:uploadTagHostFeeModalEditButton': 'Ubah FotoTree',
    'giftShop:uploadTagHostFeeModalTitle': 'Host Fee',
    'giftShop:uploadTagLabel': 'FotoTree (Opsional)',
    'giftShop:uploadTagNicknameConfirmationDescription':
      'Kamu yakin ingin memasang tag nickname ini untuk semua konten? Pastikan untuk tidak memasang tag nickname untuk foto yang salah.',
    'giftShop:uploadTagNicknameConfirmationTitle': 'Tag Nickname Yuser',
    'giftShop:uploadTitleLabel': 'Nama File',
    'giftShop:uploadTotal': '{{count}} Konten',
    'giftShop:uploadTotal_plural': '{{count}} Konten',
    'giftShop:validateKYCModalDescription':
      'Harap tunggu persetujuan akun untuk menggunakan fitur ini',
    'giftShop:validateKYCModalSubTitle':
      'Sepertinya akun kamu masih diverifikasi',
    'giftShop:validationAccountNumber': 'Nomor akun wajib diisi',
    'giftShop:validationAccountNumberNumeric':
      'Nomor akun harus berupa karater numerik',
    'giftShop:validationAccountNumberRegistered': 'Nomor akun telah terdaftar',
    'giftShop:validationBankName': 'Nama bank wajib diisi',
    'giftShop:validationBranchName': 'Cabang bank wajib diisi',
    'giftShop:validationDateOfBirth': 'Tanggal lahir wajib diisi',
    'giftShop:validationEmail': 'Email wajib diisi',
    'giftShop:validationFullname': 'Nama lengkap wajib diisi',
    'giftShop:validationPriceNumeric': 'Harga harus berupa karater numerik',
    'giftShop:verifiedAccount': 'Akun Terverifikasi',
    'giftShop:videoConfirmBeforePlayMessage':
      'Harap konfirmasi konten terlebih dahulu.',
    'giftShop:videoEditorTitle': 'Edit Pratinjau Video',
    'giftShop:videoEditorProcessing': 'Memproses...',
    'giftShop:videoPreview': 'Pratinjau Video',
    'giftShop:videoResend': 'Kirim Ulang Video: ',
    'giftShop:videoResendAddButton': 'Tambahkan Video',
    'giftShop:videoResendChangeButton': 'Ubah Video',
    'giftShop:videoResendNoDuration': 'Tidak Ada Durasi',
    'giftShop:videoResendNoFileSize': 'Tidak Ada Ukuran File',
    'giftShop:videoResendPlaceholder': 'Tambahkan versi resolusi tinggi',
    'giftShop:videoResendRequirementInfo':
      'Hi-Res Video: Maks. 30 detik, 70MB 1080p 30fps MP4 (MPEG-4)',
    'giftShop:videoUploadErrorInvalidDevice':
      'Maaf, saat ini kamu tidak bisa mengunggah pratinjau video dengan peramban iPhone',
    'giftShop:videoUploadErrorInvalidFormat': 'Format video harus MP4',
    'giftShop:videoUploadErrorMaxDuration':
      'Durasi maksimum adalah {{duration}} detik',
    'giftShop:videoUploadErrorMaxResolution':
      'Tinggi & lebar maksimum adalah {{maxRes}}',
    'giftShop:videoUploadErrorMaxSize':
      'Ukuran file maksimum adalah {{maxSize}} MB',
    'giftShop:videoUploadSendToFaceErrorInvalidFormat':
      'Format gambar harus JPEG, PNG, atau HEIC',
    'giftShop:videoUploadSendToFaceErrorMaxSize':
      'Ukuran file maksimum adalah {{maxSize}} MB',
    'giftShop:videoUploadSendToFacePlaceholder':
      'Foto dengan wajah yuser yang jelas.',
    'giftShop:virtualAccountMethod': 'Akun Virtual {{method}}',
    'giftShop:virtualAtmDescription': '',
    'giftShop:virtualAtmDescription_BCA':
      '1.Pilih Transaksi Lainnya > Transfer > Ke Rek BCA Virtual Account. \n2. Masukkan kode perusahaan 7007015 / 7007115 / 7007215 dan pilih Benar. \n3. Masukkan nomor Virtual Account {{Account}} dan pilih Benar. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah PT. Super Giga Generasi (SUPER GIGA), Total tagihan sudah benar. Jika benar pilih Ya.',
    'giftShop:virtualAtmDescription_BNI':
      '1. Pilih Transfer. \n2. Pilih Giro/Tabungan. \n3. Pilih Virtual Account Billing. \n4. Masukkan nomor Virtual Account {{Account}} dan pilih Benar. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Ya. \n6. Periksa layar konfirmasi dan pilih Ya.',
    'giftShop:virtualAtmDescription_BRI':
      '1. Pilih Transaksi Lain > Pembayaran > Lainnya > BRIVA. \n2. Masukkan Nomor BRIVA {{Account}} kemudian pilih Benar. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Ya.',
    'giftShop:virtualAtmDescription_MANDIRI':
      '1. Pilih Bayar/Beli. \n2. Pilih Lainnya > Lainnya > Multi Payment. \n3. Masukkan kode perusahaan 88608 / 88908 dan pilih Benar. \n4. Masukkan nomor Virtual Account {{Account}} dan pilih Benar. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, tekan angka 1 dan pilih Ya. \n6. Periksa layar konfirmasi dan pilih Ya.',
    'giftShop:virtualAtmDescription_PERMATA':
      '1. Pilih Transaksi Lainnya. \n2. Pilih Pembayaran > Pembayaran Lainnya. \n3. Pilih Virtual Account. \n4. Masukkan kode bayar [VA Number], kemudian pilih Benar. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Benar.',
    'giftShop:virtualiBankingDescription': '',
    'giftShop:virtualiBankingDescription_BCA':
      '1. Pilih Transfer Dana > Transfer ke BCA Virtual Account. \n2. Centang nomor Virtual Account dan masukkan {{Account}} dan pilih Lanjutkan. \n3.Periksa informasi yang tertera di layar. Pastikan Merchant adalah PT. Super Giga Generasi (SUPER GIGA), Total tagihan sudah benar. Jika benar pilih Ya.Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar pilih Lanjutkan. \n4. Masukkan respon KeyBCA kamu dan klik Kirim. ',
    'giftShop:virtualiBankingDescription_BNI':
      '1. Login ke i-Banking kamu. Pilih Transfer, kemudian pilih Virtual Account Billing. \n2. Masukkan nomor Virtual Account {{Account}}, kemudian pilih Lanjut. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, masukkan PIN kamu dan pilih OK.',
    'giftShop:virtualiBankingDescription_BRI':
      '1. Pilih menu Pembayaran. \n2. Pada kolom navigasi kiri, dalam menu Pembayaran, pilih BRIVA. \n3. Pilih rekening asal, lalu pilih Isi Kode Bayar dan masukkan nomor Virtual Account {{Account}} kemudian pilih Kirim. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, masukkan password iBanking and mToken kamu, kemudian klik Kirim.',
    'giftShop:virtualiBankingDescription_MANDIRI':
      '1. Pilih Bayar > Multi Payment \n2. Pilih Dari Rekening: Rekening kamu dan Penyedia Jasa: XENDIT, lalu klik Lanjutkan. \n3. Masukkan nomor Virtual Account {{Account}} dan pilih Lanjutkan. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, centang tagihan dan klik Lanjutkan. \n5. Masukkan PIN token kamu dan klik Kirim.',
    'giftShop:virtualiBankingDescription_PERMATA':
      '1. Pilih Pembayaran Bayar Tagihan. \n2. Pilih Virtual Account. \n3. Masukkan kode bayar {{Account}}, kemudian pilih Lanjutkan. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Selanjutnya. \n6. Masukkan Kode Respon kamu, kemudian pilih Lanjutkan.',
    'giftShop:virtualmBankingHeader': 'm-Banking',
    'giftShop:virtualmBankingHeader_MANDIRI': 'm-Banking (Livin by Mandiri)',
    'giftShop:virtualmBankingDescription': '',
    'giftShop:virtualmBankingDescription_BCA':
      '1.Pilih m-Transfer > BCA Virtual Account. \n2. Masukkan nomor Virtual Account {{Account}} dan pilih Send. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah PT. Super Giga Generasi (SUPER GIGA), Total tagihan sudah benar. Jika benar pilih Ya. \n4. Masukkan PIN m-BCA kamu dan pilih OK. ',
    'giftShop:virtualmBankingDescription_BNI':
      '1. Login ke m-Banking kamu. Pilih Transfer, kemudian pilih Virtual Account Billing. \n2. Masukkan nomor Virtual Account {{Account}}, kemudian pilih Lanjut. \n3. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, masukkan PIN kamu dan pilih OK.',
    'giftShop:virtualmBankingDescription_BRI':
      '1. Masuk ke menu Mobile Banking BRI. Kemudian, pilih Pembayaran > BRIVA. \n2. Masukkan Nomor BRIVA {{Account}} \n3. Masukkan PIN kamu kemudian pilih Send. Apabila pesan konfirmasi untuk transaksi menggunakan SMS muncul, pilih OK. Status transaksi akan dikirimkan melalui SMS dan dapat digunakan sebagai bukti pembayaran.',
    'giftShop:virtualmBankingDescription_MANDIRI':
      '1. Login ke mBanking kamu. Pilih IDR Transfer kemudian pilih Transfer to new recipient. \n2. Masukkan nomor Virtual Account {{Account}}, kemudian pilih Lanjut. \n3. Periksa informasi yang tertera di layar. Pastikan total tagihan sudah benar. Jika benar, masukkan PIN kamu dan pilih OK.',
    'giftShop:virtualmBankingDescription_PERMATA':
      '1. Pilih Pembayaran Bayar Tagihan \n2. Pilih Virtual Account. \n3. Masukkan kode bayar {{Account}}, kemudian pilih OK. \n4. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar. Jika benar, pilih Selanjutnya. \n5. Pilih nomor rekening yang akan kamu gunakan. \n6. Masukkan PIN kamu, kemudian pilih Selanjutnya.',
    'giftShop:virtualMiniAtmDescription_BRI':
      '1. Pilih Mini ATM > Pembayaran > BRIVA. \n2. Gesek kartu debit BRI kamu. \n3. Masukkan Nomor BRIVA {{Account}} kemudian tekan OK. \n4. Masukkan PIN kamu kemudian tekan OK. \n5. Periksa informasi yang tertera di layar. Pastikan Merchant adalah XENDIT, Total tagihan sudah benar, kemudian pilih Lanjut. Apabila transaksi berhasil, maka bukti transfer akan tercetak.',
    'giftShop:waitingForPayment': 'Menunggu Pembayaran',
    'giftShop:waitingForPayment_desc': '{{count}} transaksi tertunda.',
    'giftShop:wechat': 'WeChat',
    'giftShop:website': 'Situs Web',
    'giftShop:websiteLinkPlaceholder': 'Masukkan tautan situsmu',
    'giftShop:whatsapp': 'WhatsApp',
    'giftShop:whatsappTo': 'WhatsApp ke',
    'giftShop:whereYourContentIsMade': 'Dimana kontenmu dibuat?',
    'giftShop:wholesaleChangesFailed': 'Perubahan gagal disimpan.',
    'giftShop:wholesaleChangesSuccess': 'Perubahan telah disimpan.',
    'giftShop:wholesaleDiscountHigherMessage':
      'Nilai diskon tidak boleh lebih dari 50%.',
    'giftShop:wholesaleDiscountLowerMessage':
      'Nilai diskon tidak boleh sama atau lebih rendah dari nilai diskon sebelumnya.',
    'giftShop:wholesaleMaximumHigherMessage':
      'Jumlah maksimum tidak boleh lebih dari 100.',
    'giftShop:wholesaleMaximumLowerMessage':
      'Jumlah maksimum tidak boleh lebih rendah dari jumlah minimum.',
    'giftShop:wholesaleModalAllDiscountedItem': 'Diskon {{discount}}%',
    'giftShop:wholesaleModalContentAmount': 'Jumlah Konten',
    'giftShop:wholesaleModalContentAmountItem': '{{contents}} konten',
    'giftShop:wholesaleModalTitle': 'Harga Diskon',
    'giftShop:wishlist': 'FotoYu',
    'giftShop:wishlistHeader': 'Foto Kamu',
    'giftShop:withdraw': 'Penarikan',
    'giftShop:withdrawHeader': 'Penarikan',
    'giftShop:withdrawal': 'Riwayat penarikanmu akan ditampilkan di sini.',
    'giftShop:withdrawAll': 'Tarik semua',
    'giftShop:withdrawAmmount': 'Jumlah Penarikan',
    'giftShop:withdrawAmountLabel': 'Jumlah Penarikan:',
    'giftShop:withdrawCancelConfirmation':
      'Apa kamu yakin ingin membatalkan transaksi ini?',
    'giftshop:withdrawChooseBank': 'Tolong pilih satu akun bank',
    'giftShop:withdrawConfirmationDisclaimer':
      'Batas waktu penarikan mingguan :',
    'giftShop:withdrawConfirmationDisclaimer_time':
      'Senin & Kamis jam 12.00 siang WIB.',
    'giftShop:withdrawConfirmationDisclaimer_desc':
      'Penarikan selewat batas waktu akan diproses di batas selanjutnya.',
    'giftShop:withdrawConfirmationExplanationFirst':
      'Pengecekkan transaksi diproses:',
    'giftShop:withdrawConfirmationExplanationSecond':
      'Dana ditransfer selambatnya:',
    'giftShop:withdrawDescription': 'Tarik saldo menuju rekening bank kamu',
    'giftShop:withdrawDestinationLabel': 'Rekening Bank:',
    'giftShop:withdrawDetailLabel': 'Detail Penarikan',
    'giftShop:withdrawDisclaimer': 'Silahkan pilih rekening bank',
    'giftShop:withdrawConfirmation': 'Konfirmasi Penarikan',
    'giftShop:withdrawEnterPasswordPlaceholder': 'Masukkan kata sandi kamu',
    'giftShop:withdrawFee': 'Biaya Penarikan',
    'giftShop:withdrawInvalidPassword':
      'Kata sandi salah, silakan masukkan kata sandi yang benar. Kamu punya {{attempt}} kesempatan tersisa.',
    'giftShop:withdrawInvalidPassword_limit':
      'Melebihi batas input kata sandi. Coba lagi nanti',
    'giftShop:withdrawButtonConfirm': 'Tarik {{price}}',
    'giftShop:withdrawErrorMessage': 'Terjadi eror saat melakukan penarikan',
    'giftShop:withdrawErrorMessage_BANK_ACCOUNT_LIMIT':
      'Kamu tidak dapat memiliki lebih dari 3 akun bank.',
    'giftShop:withdrawErrorMessage_NO_BANK_ACCOUNT':
      'Tolong pilih satu akun bank.',
    'giftShop:withdrawErrorMessage_INSUFFICIENT_BALANCE':
      'Maaf, saldo kamu tidak cukup untuk melakukan penarikan.',
    'giftShop:withdrawErrorMessage_WITHDRAW_NO_AMOUNT':
      'Harap masukkan jumlah penarikan yang valid.',
    'giftShop:withdrawErrorMessage_WITHDRAW_ZERO_AMOUNT':
      'Jumlah penarikan tidak valid.',
    'giftShop:withdrawErrorMessage_WITHDRAW_MIN_AMOUNT':
      'Minimum penarikan adalah Rp 50.000,00',
    'giftShop:withdrawErrorMessage_WITHDRAW_MAX_AMOUNT':
      'Maksimum penarikan adalah Rp 100.000.000,00',
    'giftShop:withdrawErrorMessage_WITHDRAW_MAX_HOST':
      'Kamu hanya bisa melakukan penarikan 1x/hari.',
    'giftShop:withdrawErrorMessage_WITHDRAW_FEE_ERROR':
      'Terjadi eror saat melakukan penarikan. Silakan coba lagi nanti.',
    'giftShop:withdrawProcessFailed':
      'Terdapat masalah ketika memproses permintaanmu.',
    'giftShop:withdrawProcessSuccess': 'Permintaan penarikan sedang diproses',
    'giftShop:xOfy': '{{x}} dari {{y}}',
    'giftShop:yourPhotos': 'Foto Kamu',
  },
}
