import React, {useMemo, useState} from 'react'
import styled from 'styled-components'
import {AUTH_USER_MIN_AGE, IMAGE_ASSET} from 'consts'
import {useTranslation} from 'i18n'
import {useHistory} from 'utils'
import {Button, Image, Paragraph} from 'common/components'
import convertUnit from 'lib/unit'
import {GiftShopRobopetChildIncomplete} from '../../../gift-shop'
import AuthEasySignupChildrenRegisterParent from './AuthEasySignupChildrenRegisterParent'

const StyledHeader = styled(Paragraph)`
  text-align: center;
`

const StyledImageContainer = styled.div`
  justify-content: center;
  display: flex;
  margin-top: ${convertUnit(30)};
`

const StyledRobopet = styled(Image)`
  object-fit: contain;
  width: ${convertUnit(226)};
  height: ${convertUnit(140)};
  flex-grow: 0;
`

const StyledParagraph = styled(Paragraph)`
  width: ${convertUnit(280)};
  text-align: center;
  display: inline;
`

const StyledParagraphContainer = styled.div`
  justify-content: center;
  margin-top: ${convertUnit(20)};
  text-align: center;
`

const StyledButton = styled(Button)`
  margin-top: ${convertUnit(20)};
`
interface AuthEasySignupUnderageProps {
  isChildReapply?: boolean
}
export default function AuthEasySignupUnderage({
  isChildReapply = false,
}: AuthEasySignupUnderageProps) {
  const {translate} = useTranslation()
  const img = IMAGE_ASSET('giftshop', 'robopet-offer.png')
  const history = useHistory()
  type UnderageScreen = 'underage' | 'parentDob' | 'incomplete'
  const [screenType, setScreenType] = useState<UnderageScreen>(
    isChildReapply ? 'incomplete' : 'underage',
  )

  const handleRenderUnderageContent = useMemo(
    () => (
      <>
        <StyledHeader fontSize="xl" fontWeight="bold">
          {translate('auth:loginUnderage')}
        </StyledHeader>
        <StyledParagraphContainer>
          <StyledParagraph fontSize="l">
            {translate('auth:loginUnderageHeader', {age: AUTH_USER_MIN_AGE})}
          </StyledParagraph>
        </StyledParagraphContainer>
        <StyledImageContainer>
          <StyledRobopet src={img} alt="img" />
        </StyledImageContainer>
        <StyledParagraphContainer>
          <StyledParagraph fontSize="m">
            {translate('auth:loginUnderageDescription')}
          </StyledParagraph>
        </StyledParagraphContainer>
        <StyledButton
          label={translate('auth:askParentalConsent')}
          onClick={() => setScreenType('parentDob')}
        />
        <StyledButton
          backgroundColor="white_3"
          color="primary_5"
          label={translate('auth:messageBackToLogin')}
          onClick={() => history.push('auth_login', {})}
        />
      </>
    ),
    [history, img, translate],
  )

  const handleRenderContent = useMemo(() => {
    switch (screenType) {
      case 'underage':
        return handleRenderUnderageContent
      case 'parentDob':
        return (
          <AuthEasySignupChildrenRegisterParent
            isChildReapply={isChildReapply}
          />
        )
      case 'incomplete':
        return (
          <GiftShopRobopetChildIncomplete
            onClick={() => setScreenType('parentDob')}
          />
        )
      default:
        return <></>
    }
  }, [handleRenderUnderageContent, isChildReapply, screenType])

  return handleRenderContent
}
