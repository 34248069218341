import React, {useMemo} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'i18n'
import {getContentURL, getCurrencyValue} from 'utils'
import {useWindowMode} from 'windows'
import {Paragraph, Image} from 'common/components'
import convertUnit from 'lib/unit'
import {TransactionDetailContentItemProps} from './TransactionDetailContentItemProps'

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: ${convertUnit(100)};
  margin: ${convertUnit(10)} 0;
  cursor: pointer;
`

const StyledItem = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  margin-left: ${convertUnit(5)};
`

const StyledGridPhoto = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: ${convertUnit(15)};
`

const StyledItemEnd = styled.div`
  display: flex;
  margin-left: ${convertUnit(70)};
  flex-direction: column;
  justify-content: center;
  align-items: end;
`

const StyledStrikeThroughParagraph = styled(Paragraph)`
  text-decoration: line-through;
`

const StyledParagraph = styled(Paragraph)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  overflow-wrap: anywhere;
`

const StyledImage = styled(Image)`
  width: ${convertUnit(80)};
  height: ${convertUnit(80)};
`

export default function TransactionDetailContentItem({
  item,
  discount,
  showDiscount = false,
  onClickItem,
}: TransactionDetailContentItemProps) {
  const {translate} = useTranslation()
  const mode = useWindowMode()
  const {url, type, title, price, creator_name, creator_price} = item
  const isDiscounted = useMemo(
    () => showDiscount && !!creator_price && creator_price !== price,
    [creator_price, price, showDiscount],
  )

  return (
    <StyledItemContainer onClick={() => onClickItem(item)}>
      <StyledGridPhoto>
        <StyledImage
          useLazyLoad
          src={getContentURL({url, type})}
          alt={`content-item:${title}`}
          style={{borderRadius: convertUnit(8)}}
        />
      </StyledGridPhoto>
      <StyledItem>
        {isDiscounted ? (
          <Paragraph fontSize="xs" fontWeight="bold" color="primary_5">
            {`${translate('giftShop:discount')} ${discount?.percentage}%`}
          </Paragraph>
        ) : (
          <></>
        )}
        <StyledParagraph fontSize="m" fontWeight="bold">
          {title}
        </StyledParagraph>
        <Paragraph color="gray_5">
          {mode === 'mobile' && creator_name && creator_name.length > 15
            ? `${creator_name.slice(0, 15)}...`
            : creator_name}
        </Paragraph>
      </StyledItem>
      <StyledItemEnd>
        <Paragraph color="gray_5" fontSize="xs" fontWeight="medium">
          {translate('giftShop:price')}
        </Paragraph>
        {!!creator_price && creator_price !== price ? (
          <StyledStrikeThroughParagraph
            fontSize="s"
            fontWeight="bold"
            color="gray_3">
            {getCurrencyValue(creator_price)}
          </StyledStrikeThroughParagraph>
        ) : (
          <></>
        )}
        <Paragraph fontSize="m" fontWeight="bold" color="primary_5">
          {getCurrencyValue(price)}
        </Paragraph>
      </StyledItemEnd>
    </StyledItemContainer>
  )
}
